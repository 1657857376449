import React from "react";
import moviesStyles from "./HomeMovies.module.css";
import movieBanner from "../../api/uploads/IMG-break.jpg";
import movieBanner2 from "../../api/uploads/IMG-nigimg.jpg";
import movieBanner3 from "../../api/uploads/IMG-utali.jpg";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";

const HomeMovies = () => {
  const navigate = useNavigate();

  const goToMoviesPage = () => {
    navigate("/movies");
  };
  return (
    <div className={moviesStyles.homeBoxMovie}>
      <div className={moviesStyles.h2MovieBox}>
        <h2>Watch Movies</h2>
        <p>Watch Premium & Interesting Movies </p>
      </div>
      <div className={moviesStyles.homeMoviesBox}>
        <div>
          <img src={movieBanner} alt="Movie-banner" onClick={goToMoviesPage} />
        </div>

        <div>
          <img src={movieBanner2} alt="Movie-banner" onClick={goToMoviesPage} />
        </div>

        <div className={moviesStyles.homeMovie3}>
          <img src={movieBanner3} alt="Movie-banner" onClick={goToMoviesPage} />
        </div>
      </div>

      <div className={moviesStyles.btnBoxWatch}>
        <ButtonPrimary btnValue="Watch Movies" btnAction={goToMoviesPage} />
      </div>
    </div>
  );
};

export default HomeMovies;
