import React, { useEffect, useState } from "react";
import problemStyles from "./Problems.module.css";
import awardImg from "../../assets/award.svg";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import ShimmerLoader from "../ShimmerLoader/ShimmerLoader";
import $ from "jquery";

const Problems = () => {
  const [dataItems, setDataItems] = useState([]);
  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getProbs.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.probs);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  const navigate = useNavigate();
  return (
    <div className={problemStyles.probBox}>
      <div className={problemStyles.prodBoxText}>
        <h2>The Biggest Problems Facing Actors and Media Practioners Today</h2>

        {dataItems.length > 0 ? (
          dataItems.map((item, index) => {
            return (
              <div className={problemStyles.prodpBox} key={index}>
                <p>
                  {index + 1}. {item.item}
                </p>
              </div>
            );
          })
        ) : (
          <ShimmerLoader />
        )}

        <ButtonPrimary
          btnValue="Join Now"
          btnAction={() => {
            navigate("/join_us");
            window.scrollTo(0, 0);
          }}
        />
      </div>

      <div className={problemStyles.prodBoxImg}>
        <img src={awardImg} alt="award" />

        <div className={problemStyles.textBox}>
          <p className={problemStyles.textBox1}>
            -Nigerian Celebrities Achievers Award-
          </p>
          <p>Best Film Academy South East of Nigeria 2019</p>
        </div>
      </div>
    </div>
  );
};

export default Problems;
