import React from "react";
import textAreaStyles from "./TextArea.module.css";

const TextArea = (props) => {
  return (
    <div>
      <div className={textAreaStyles.inputMain}>
        <textarea
          placeholder={props.placeHolder}
          type={props.type}
          name={props.name}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
