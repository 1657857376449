import React from "react";
import vid2 from "../../assets/vid2.mp4";
import videoStyles from "./VideoPlayback.module.css";

const VideoPlayback = () => {
  return (
    <div className={videoStyles.videoContainer}>
      <video
        autoPlay={true}
        loop
        muted
        className={videoStyles.videoClip}
        height="100"
        preload="none"
      >
        <source src={vid2} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
};

export default VideoPlayback;
