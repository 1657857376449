import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import Preloader from "../../components/Preloader/Preloader";
import $ from "jquery";

const Movies = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [dataItems, setDataItems] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [courseNameVal, setCourseNameVal] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [id2, setId] = useState("");
  const fileRef2 = useRef();
  const courseName = useRef();
  const coursePrice = useRef();
  const shortDesc2 = useRef();
  const fullDesc = useRef();

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  const success = (msg) => {
    toast.success(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getMovies.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.movies);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  const editCourseHandler = (
    id,
    name,
    price,
    shortDesc,
    fulldesc,
    thubmbNail
  ) => {
    setShowModal1(true);
    setTimeout(() => {
      courseName.current.value = name;
      coursePrice.current.value = price;
      shortDesc2.current.value = shortDesc;
      fullDesc.current.value = fulldesc;
      setId(id);
      setimgUrl(thubmbNail);
    }, 500);
  };

  const handleSubmitHandler = (e) => {
    e.preventDefault();

    const CName = e.target.elements.CName.value;
    const CPrice = e.target.elements.CPrice.value;
    const Cdesc = e.target.elements.Cdesc.value;
    const Fdesc = e.target.elements.Fdesc.value;

    if (CName === "") {
      notify("Movie title required");
    } else if (CPrice === "") {
      notify("Movie duration  required");
    } else if (Cdesc === "") {
      notify("Short Description required");
    } else if (Fdesc === "") {
      notify("Full Description required");
    } else {
      const formData = new FormData();
      const file = fileRef2.current.files[0];
      formData.append("id", id2);
      formData.append("movieTitle", CName);
      formData.append("movieDuration", CPrice);
      formData.append("shortDesc", Cdesc);
      formData.append("fullDesc", Fdesc);
      imgUrl.includes("IMG")
        ? formData.append("image", imgUrl)
        : formData.append("file", file);

      setisloading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateMovie.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("Course updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleUploadVideo = (id, courseName) => {
    setShowModal2(true);
    setCourseNameVal(courseName);
    setId(id);
  };

  const handleUploadVideoSubmit = (e) => {
    e.preventDefault();
    const videoID = e.target.video.value;

    if (videoID === "") {
      notify("Video ID required");
    } else {
      setisloading(true);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/uploadMovieVideo.php`,
        data: { id: id2, video: videoID },
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("Movie Updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const InsetNewMovie = () => {
    setShowModal3(true);
  };

  const addNewMovieHandler = (e) => {
    e.preventDefault();

    const mTitle = e.target.elements.mTitle.value;
    const mDuration = e.target.elements.mDuration.value;
    const mShort = e.target.elements.mShort.value;
    const mFull = e.target.elements.mFull.value;
    if (mTitle === "") {
      notify("Movie title required");
    } else if (mDuration === "") {
      notify("Movie duration  required");
    } else if (mShort === "") {
      notify("Short Description required");
    } else if (mFull === "") {
      notify("Full Description required");
    } else if (e.target.file.files.length === 0) {
      notify("Thumbnail required");
    } else {
      const formData = new FormData();
      const file = e.target.file.files[0];
      formData.append("movieTitle", mTitle);
      formData.append("movieDuration", mDuration);
      formData.append("shortDesc", mShort);
      formData.append("fullDesc", mFull);
      formData.append("file", file);
      setisloading(true);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/addNewMovie.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("New Movie Added");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const deleteMovieHandler = (id) => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/deleteMovie.php`,
      data: { id: id },
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setIsRender(!isRender);
          setShowModal1(false);
          success("Movie deleted");
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };
  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh3}>
            <h2>Movies</h2>
          </div>

          {dataItems.length > 0
            ? dataItems.map((data, index) => {
                return (
                  <div className={adminStyles.mainBox4} key={index}>
                    <div className={adminStyles.imgBox4}>
                      <img
                        src={`${baseUrl}/api/uploads/${data.movie_thumbnail}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Movie Title</p>
                      <span>{data.movie_title}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Movie Duration</p>
                      <span>{data.movie_duration}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Short Description</p>
                      <span>{data.movie_desc1}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Full Description</p>
                      <span>{data.movie_desc}</span>
                    </div>

                    <div className={adminStyles.imgBox4}>
                      <div className={adminStyles.VideoYoutubeBox2}>
                        <iframe
                          className={adminStyles.iframeStyle}
                          title="Youtube"
                          aria-hidden="true"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${data.movie_video}?autoplay=1>
                          <img src=${baseUrl}/api/uploads/${data.movie_thumbnail} alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a></style>`}
                        ></iframe>
                      </div>
                    </div>

                    <div className={adminStyles.boxBtn1}>
                      <button
                        onClick={() =>
                          editCourseHandler(
                            data.id,
                            data.movie_title,
                            data.movie_duration,
                            data.movie_desc1,
                            data.movie_desc,
                            data.movie_thumbnail
                          )
                        }
                      >
                        Edit Movie
                      </button>
                      <button
                        className={adminStyles.boxBtn3}
                        onClick={() =>
                          handleUploadVideo(data.id, data.movie_title)
                        }
                      >
                        Upload Movie Video
                      </button>

                      <button onClick={() => deleteMovieHandler(data.id)}>
                        Delete Movie
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.boxBtn1}>
          <button className={adminStyles.boxBtn3} onClick={InsetNewMovie}>
            Add New Movie
          </button>
        </div>
      </div>
      <Footer />

      {showModal1 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal1(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Movie</p>

            <form onSubmit={handleSubmitHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Movie Title</label>
                <input
                  placeholder="Movie Title"
                  ref={courseName}
                  name="CName"
                />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Movie Duration</label>
                <input
                  placeholder="Movie Duration"
                  ref={coursePrice}
                  name="CPrice"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Short Description</label>
                <input
                  placeholder="Short Description"
                  ref={shortDesc2}
                  name="Cdesc"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Full Description</label>
                <textarea
                  placeholder="Full Description"
                  name="Fdesc"
                  ref={fullDesc}
                ></textarea>
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Edit Thumbnail</label>
                <input
                  placeholder="Short Description"
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl.includes("IMG") ? (
                  <img src={`${baseUrl}/api/uploads/${imgUrl}`} alt="img" />
                ) : (
                  <img src={imgUrl} alt="img" />
                )}
              </div>

              <ButtonPrimary btnValue="Save Changes" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal2 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal2(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Upload {courseNameVal} Movie Video</p>

            <form onSubmit={handleUploadVideoSubmit}>
              <div className={adminStyles.formMainInput}>
                <label>Youtube Video ID</label>
                <input
                  ref={fileRef2}
                  name="video"
                  type="text"
                  placeholder="Youtube Video ID"
                />
              </div>

              <ButtonPrimary btnValue="Upload" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal3 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal3(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Add New Movie</p>

            <form onSubmit={addNewMovieHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Movie Title</label>
                <input placeholder="Movie Title" name="mTitle" />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Movie Duration</label>
                <input placeholder="Movie Duration" name="mDuration" />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Short Description</label>
                <input placeholder="Short Description" name="mShort" />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Full Description</label>
                <textarea
                  placeholder="Full Description"
                  name="mFull"
                ></textarea>
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Thumbnail</label>
                <input
                  placeholder="Short Description"
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl !== "" ? <img src={imgUrl} alt="img" /> : null}
              </div>

              <ButtonPrimary btnValue="Add Movie" />
            </form>
          </div>
        </div>
      ) : null}

      {isloading ? <Preloader /> : null}
      <ToastContainer theme="light" />
    </div>
  );
};

export default Movies;
