import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import watchMovies from "./WatchMovies.module.css";
import MovieCard from "../../components/MovieCard/MovieCard";
import Footer from "../../components/Footer/Footer";
import { baseUrl } from "../../utilities/apiUrl";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const WatchMovies = () => {
  titleUpdater(
    "Watch Exclusive, Entertaining and Premium Movies for your entertainment and delight."
  );
  const navigation = useNavigate();
  const [loading, setloading] = useState(true);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getMovies.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setMovies(data.movies);
          setloading(false);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  const showMovie = (id, name) => {
    let nameLink = name.replace(/\s/g, "-");
    navigation(`/movie?id=${id}&name=${nameLink}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={watchMovies.storeContainer}>
      <Header />

      <div className={watchMovies.storeBox}>
        {movies.length > 0
          ? movies.map((movie, index) => {
              return (
                <div key={movie.id}>
                  <MovieCard
                    img={`${baseUrl}/api/uploads/${movie.movie_thumbnail}`}
                    movieTitle={movie.movie_title}
                    movieDuration={movie.movie_duration}
                    movieDesc={movie.movie_desc1}
                    showMovie={() => showMovie(movie.id, movie.movie_title)}
                  />
                </div>
              );
            })
          : null}
      </div>

      {loading ? <Preloader /> : null}
      <Footer />
    </div>
  );
};

export default WatchMovies;
