import React, { useEffect, useState } from "react";
import testimonialStyles from "./Testimonials.module.css";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import { baseUrl } from "../../utilities/apiUrl";
import ShimmerLoader from "../ShimmerLoader/ShimmerLoader";
import $ from "jquery";

const Testimonials = () => {
  const [dataItem, setData] = useState([]);
  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getTestimonials.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setData(data.testimonials);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  return (
    <div className={testimonialStyles.testimonialBox}>
      <div className={testimonialStyles.testimonialTextHeader}>
        <h2>Comments & Reviews</h2>
        <p>
          Don't just take our words for it, here are our students review and
          comments.
        </p>
      </div>

      <div className={testimonialStyles.testimoniaalBoxCard}>
        {dataItem.length > 0 ? (
          dataItem.map((item, index) => {
            return (
              <div key={index}>
                <TestimonialCard
                  pic={`${baseUrl}/api/uploads/${item.pic}`}
                  ratingName={item.name}
                  testimonialText={item.remark}
                />
              </div>
            );
          })
        ) : (
          <ShimmerLoader />
        )}
      </div>
    </div>
  );
};

export default Testimonials;
