import React, { useRef, useState } from "react";
import textInputStyles from "./TextInput.module.css";
import { IoEyeOffOutline } from "react-icons/io5";
import { LuEye } from "react-icons/lu";

const TextInput = (props) => {
  const passRef = useRef();
  const [showPass, setShowPass] = useState(textInputStyles.inputIcon);
  const [showPass2, setShowPass2] = useState(textInputStyles.hide);

  const handlePassword = () => {
    if (passRef.current.type === "password") {
      passRef.current.type = "text";
      setShowPass(textInputStyles.hide);
      setShowPass2(textInputStyles.inputIcon);
    } else {
      passRef.current.type = "password";
      setShowPass2(textInputStyles.hide);
      setShowPass(textInputStyles.inputIcon);
    }
  };

  return (
    <div className={textInputStyles.textInputBox}>
      <label>{props.labelName}</label>
      <div className={textInputStyles.inputMain}>
        <input
          placeholder={props.placeHolder}
          type={props.type}
          name={props.name}
          ref={passRef}
        />
        {props.type === "password" ? (
          <>
            <IoEyeOffOutline
              className={[showPass, textInputStyles.iconSize].join(" ")}
              onClick={handlePassword}
            />
            <LuEye
              className={[showPass2, textInputStyles.iconSize].join(" ")}
              onClick={handlePassword}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TextInput;
