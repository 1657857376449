import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import storeStyles from "./Store.module.css";
import StoreCard from "../../components/StoreCard/StoreCard";
import Footer from "../../components/Footer/Footer";
import { baseUrl } from "../../utilities/apiUrl";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";
import { convertPrice } from "../../utilities/convertPrice";
import { titleUpdater } from "../../utilities/titleUpdater";

import $ from "jquery";
const Store = () => {
  titleUpdater(
    "Store | Get standard and professional media and tech gadgets from our store to give your that professional digital feel."
  );
  const navigation = useNavigate();
  const [loading, setloading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getProducts.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setProducts(data.products);
          setloading(false);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  const showProduct = (id, name) => {
    let nameLink = name.replace(/\s/g, "-");
    navigation(`/product?id=${id}&name=${nameLink}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={storeStyles.storeContainer}>
      <Header />

      <div className={storeStyles.storeBox}>
        {products.length > 0
          ? products.map((product, index) => {
              return (
                <div key={product.id}>
                  <StoreCard
                    img={`${baseUrl}/api/uploads/${product.prodImg}`}
                    prodName={product.prodname}
                    prodPrice={convertPrice(product.prodprice)}
                    showProduct={() =>
                      showProduct(product.id, product.prodname)
                    }
                    desc={product.proddesc}
                  />
                </div>
              );
            })
          : null}
      </div>

      {loading ? <Preloader /> : null}
      <Footer />
    </div>
  );
};

export default Store;
