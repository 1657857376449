import React from "react";
import shimStyles from "./ShimmerLoader.module.css";

const ShimmerLoader = () => {
  return (
    <div className={shimStyles.container}>
      <article>
        <div className={shimStyles.line}></div>
        <div className={shimStyles.line}></div>
        <div className={shimStyles.line}></div>
        <div className={shimStyles.shimmer}></div>
      </article>
    </div>
  );
};

export default ShimmerLoader;
