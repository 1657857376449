import React, { useEffect, useState, useRef } from "react";
import mentorshipStyles from "./Membership.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Preloader from "../../components/Preloader/Preloader";
import { PaystackConsumer } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { IoCameraOutline } from "react-icons/io5";
import { baseUrl } from "../../utilities/apiUrl";
import memberImg from "../../assets/join.jpg";
import TextInput from "../../components/TextInput/TextInput";
import InputFlex from "../InputFlex/InputFlex";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import TextArea from "../TextArea/TextArea";
import youtubeImg from "../../assets/youtube.jpg";
import { ToastContainer, toast } from "react-toastify";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const Membership = () => {
  titleUpdater(
    "Become A Memeber of the Magnus Media production Team and gain More Experience and Royalties."
  );
  const apiKey = process.env.REACT_APP_API_KEY;
  const em = localStorage.getItem("em");
  const isSub = localStorage.getItem("isSub");
  const fileRef = useRef();
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");
  const [loading, setIsLoading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [sub, setSub] = useState(false);
  const [img, setImg] = useState("");
  const [agree, setAgreement] = useState(false);
  const [isProfile, setProfile] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [isModal, setIsmodal] = useState(false);
  const [profileId, setProfileId] = useState("");
  const fullName = useRef();
  const email = useRef();
  const phone = useRef();
  const address = useRef();
  const gender = useRef();
  const bio = useRef();
  const bankName = useRef();
  const acctName = useRef();
  const acctNum = useRef();
  const fLink = useRef();
  const iLink = useRef();
  const tLink = useRef();
  const tiLink = useRef();
  const skill = useRef();
  const typeYes = useRef();
  const typeNo = useRef();
  const lang = useRef();
  const sport = useRef();
  const idoff = useRef();
  const customId = "id-static";

  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const success = (msg) =>
    toast.success(msg, {
      toastId: customId,
      autoClose: 30000,
    });

  const config = {
    reference: new Date().getTime().toString(),
    email: auth ? em : "",
    amount: (20000 + 1000) * 100,
    publicKey: apiKey,
  };

  useEffect(() => {
    setIsLoading(true);

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getMentorPayment.php`,
      data: { email: em, check: "membership" },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        setIsLoading(false);
        setTimeout(() => {
          if (isSub) {
            setSub(false);
          } else {
            setSub(true);
          }
        }, 1500);
        if (data.error) {
          if (data.error === "Payment expired") {
            notify("Payment Expired, Please check your mail");
          }
        } else {
          const value = data.payments.filter(
            (item, index) => item.email === em && item.pay_type === "membership"
          );
          if (value.length > 0) {
            setIsPay(true);
            setProfileId(value[0].profileId);
            $.ajax({
              type: "POST",
              url: `${baseUrl}/api/getProfile.php`,
              data: {
                profileId: value[0].profileId,
              },
            })
              .then(function (res) {
                let data = JSON.parse(res);
                if (data.error) {
                  console.log(data.error);
                } else {
                  setProfile(true);
                  setProfileInfo(data);
                }
              })
              .fail(function (res) {
                console.log(res);
              });
          } else {
            setIsPay(false);
          }
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  const handleSuccess = () => {
    setIsLoading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/mentorPayment.php`,
      data: {
        email: em,
        type: "membership",
        price: 20000,
        id: Math.floor(Math.random() * 9000),
      },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setIsLoading(false);
          setIsRender(!isRender);
          window.scrollTo(0, 0);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };
  const handleClose = () => {
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Subscribe to watch",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const handleCreateProfile = (e) => {
    e.preventDefault();
    const fullName = e.target.elements.fName.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const address = e.target.elements.address.value;
    const gender = e.target.elements.gender.value;
    const bio = e.target.elements.bio.value;
    const bankName = e.target.elements.bankName.value;
    const acctName = e.target.elements.acctName.value;
    const acctNum = e.target.elements.acctNum.value;
    const fLink = e.target.elements.fLink.value;
    const iLink = e.target.elements.iLink.value;
    const tLink = e.target.elements.tLink.value;
    const tiLink = e.target.elements.tiLink.value;
    const skill = e.target.elements.skill.value;
    const type = e.target.elements.type.value;
    const lang = e.target.elements.lang.value;
    const sport = e.target.elements.sport.value;
    const idoff = e.target.elements.idoff.value;

    if (fullName === "") {
      notify("Full name required");
    } else if (email === "") {
      notify("Email required");
    } else if (phone === "") {
      notify("Phone Number required");
    } else if (address === "") {
      notify("Address required");
    } else if (gender === "") {
      notify("Gender required");
    } else if (bio === "") {
      notify("Brief biography required");
    } else if (bankName === "") {
      notify("Bank name required");
    } else if (acctName === "") {
      notify("Account name required");
    } else if (acctNum === "") {
      notify("Account number required");
    } else if (skill === "") {
      notify("Skills & Specializations required");
    } else if (type === "") {
      notify("Your tell us your availablity status");
    } else if (lang === "") {
      notify("Languages required");
    } else if (sport === "") {
      notify("Sport activities required");
    } else if (img === "") {
      notify("Upload your Head Shot");
    } else if (agree === false) {
      notify("Please agree to our service terms to continue");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("fullname", fullName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("bankName", bankName);
      formData.append("acctName", acctName);
      formData.append("acctNum", acctNum);
      formData.append("facebook", fLink);
      formData.append("instagram", iLink);
      formData.append("twitter", tLink);
      formData.append("tiktok", tiLink);
      formData.append("skill", skill);
      formData.append("available", type);
      formData.append("language", lang);
      formData.append("physicalActivity", sport);
      formData.append("idoffUrl", idoff);
      formData.append("id", profileId);
      formData.append("file", fileRef.current.files[0]);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/createProfile.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setIsLoading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            success(
              "Your Artist Portfolio and Membership was sucessfully created, please check your email"
            );
            window.scrollTo(0, 0);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const selecProfilePix = () => {
    fileRef.current.click();
  };
  const selectProfilePic = () => {
    const profilePix = fileRef.current.files[0];
    let url = URL.createObjectURL(profilePix);
    setImg(url);
  };

  const setCheckAgreement = () => {
    setAgreement(!agree);
  };

  const handleEditView = () => {
    setIsmodal(true);
    setTimeout(() => {
      fullName.current.value = profileInfo.fullname;
      email.current.value = profileInfo.email;
      phone.current.value = profileInfo.phone;
      address.current.value = profileInfo.addres;
      gender.current.value = profileInfo.gender;
      bio.current.value = profileInfo.bio;
      bankName.current.value = profileInfo.bankName;
      acctName.current.value = profileInfo.acctName;
      acctNum.current.value = profileInfo.acctNum;
      fLink.current.value = profileInfo.facebook;
      iLink.current.value = profileInfo.instagram;
      tLink.current.value = profileInfo.twitter;
      tiLink.current.value = profileInfo.tiktok;
      skill.current.value = profileInfo.skill;
      profileInfo.available === "Yes"
        ? typeYes.current.click()
        : typeNo.current.click();

      lang.current.value = profileInfo.languag;
      sport.current.value = profileInfo.physicalActivity;
      idoff.current.value = profileInfo.idoffUrl;
      setImg(profileInfo.img);
    }, 500);
  };
  const handleEditProfile = (e) => {
    e.preventDefault();
    const fullName = e.target.elements.fName.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const address = e.target.elements.address.value;
    const gender = e.target.elements.gender.value;
    const bio = e.target.elements.bio.value;
    const bankName = e.target.elements.bankName.value;
    const acctName = e.target.elements.acctName.value;
    const acctNum = e.target.elements.acctNum.value;
    const fLink = e.target.elements.fLink.value;
    const iLink = e.target.elements.iLink.value;
    const tLink = e.target.elements.tLink.value;
    const tiLink = e.target.elements.tiLink.value;
    const skill = e.target.elements.skill.value;
    const type = e.target.elements.type.value;
    const lang = e.target.elements.lang.value;
    const sport = e.target.elements.sport.value;
    const idoff = e.target.elements.idoff.value;

    if (fullName === "") {
      notify("Full name required");
    } else if (email === "") {
      notify("Email required");
    } else if (phone === "") {
      notify("Phone Number required");
    } else if (address === "") {
      notify("Address required");
    } else if (gender === "") {
      notify("Gender required");
    } else if (bio === "") {
      notify("Brief biography required");
    } else if (bankName === "") {
      notify("Bank name required");
    } else if (acctName === "") {
      notify("Account name required");
    } else if (acctNum === "") {
      notify("Account number required");
    } else if (skill === "") {
      notify("Skills & Specializations required");
    } else if (type === "") {
      notify("Your tell us your availablity status");
    } else if (lang === "") {
      notify("Languages required");
    } else if (sport === "") {
      notify("Sport activities required");
    } else if (img === "") {
      notify("Upload your Head Shot");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", profileInfo.id);
      formData.append("fullname", fullName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("gender", gender);
      formData.append("bio", bio);
      formData.append("bankName", bankName);
      formData.append("acctName", acctName);
      formData.append("acctNum", acctNum);
      formData.append("facebook", fLink);
      formData.append("instagram", iLink);
      formData.append("twitter", tLink);
      formData.append("tiktok", tiLink);
      formData.append("skill", skill);
      formData.append("available", type);
      formData.append("language", lang);
      formData.append("physicalActivity", sport);
      formData.append("idoffUrl", idoff);
      img.includes("IMG-")
        ? formData.append("image", img)
        : formData.append("file", fileRef.current.files[0]);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateProfile.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setIsLoading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setIsmodal(false);
            window.scrollTo(0, 0);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleSubscription = () => {
    localStorage.setItem("isSub", true);
    window.location.href = "https://www.youtube.com/c/magnusmediatv";
  };

  return (
    <div className={mentorshipStyles.previewBox}>
      <Header />

      <div className={mentorshipStyles.previewContainer}>
        {!isPay ? (
          <div className={mentorshipStyles.imgBoxContain}>
            <img src={memberImg} alt="thumbnail" />
          </div>
        ) : null}

        <div className={mentorshipStyles.containerText}>
          {!isPay ? <h2>MEMBERSHIP INTO MAGNUS MEDIA PRODUCTION</h2> : null}

          {!isPay ? (
            <p className={mentorshipStyles.containerPtext}>
              This empowers aspiring actors and media practitioners with
              exclusive membership benefits, including royalties from their
              productions, seamless connections to the Nigerian film industry,
              and opportunities to get paid for featuring in movies,
              documentaries, music videos, events, and various media contracts
              facilitated by Magnus Media Productions.
            </p>
          ) : null}

          {!isPay ? (
            <div className={mentorshipStyles.learnBox}>
              <p className={mentorshipStyles.learnBoxText}>
                Benefits and Features
              </p>
              <div>
                <li>One Year Membership</li>
                <li>Drop your bank details to be paid Royalty.</li>
                <li>
                  As a member, you will be called upon to feature in our cinema
                  movies, documentaries, music videos, event and media contracts
                  and get PAID.
                </li>
                <li>Connection to Nollywood</li>
                <li>
                  We will assist you to get registered in any Creative Guild of
                  your choice like AGN, DGN, AMP as you comnect to industry
                  practitioners.
                </li>
              </div>
            </div>
          ) : null}

          {!isPay ? (
            <div className={mentorshipStyles.priceBox}>
              <p>NGN 20,000</p>
            </div>
          ) : null}

          {isPay && isProfile === false ? (
            <div className={mentorshipStyles.mentText}>
              <h2>CREATE YOUR ARTIST PORTFOLIO/ SHOWREEL</h2>
              <p>
                Please Fill the form below to create your artist
                portfolio/showreel
              </p>
            </div>
          ) : null}

          {isPay && isProfile === true ? (
            <div className={mentorshipStyles.profileBox1}>
              <h2>Membership Portfolio</h2>
              <div className={mentorshipStyles.profileImg}>
                <img
                  src={`${baseUrl}/api/uploads/${profileInfo.img}`}
                  alt="profile-img"
                />
              </div>
              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Full Name</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.fullname}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Email Address</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.email}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Phone Number</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.phone}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Address</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.addres}
                </p>
              </div>
              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Gender</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.gender}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>Biography</p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.bio}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>
                  Available for movies, projects?
                </p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.available}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>
                  Languages Spoken
                </p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.languag}
                </p>
              </div>

              <div className={mentorshipStyles.profileBox2}>
                <p className={mentorshipStyles.profinfo1Tex1}>
                  Physical Abilities
                </p>
                <p className={mentorshipStyles.profinfo1Tex2}>
                  {profileInfo.physicalActivity}
                </p>
              </div>

              <div className={mentorshipStyles.profileEditBox}>
                <div>
                  <a href="https://api.whatsapp.com/send/?phone=+2348032684423">
                    Chat Admin
                  </a>
                </div>
                <div className={mentorshipStyles.profileEditBox1}>
                  <p onClick={handleEditView}>Edit Profile</p>
                </div>
              </div>
            </div>
          ) : null}

          {isPay && isProfile === false ? (
            <div className={mentorshipStyles.boxContainbx}>
              <form onSubmit={handleCreateProfile}>
                <TextInput
                  placeHolder="Full Name"
                  labelName="Full Name"
                  name="fName"
                />
                <InputFlex
                  labelName1="Your Email"
                  labelName2="Your Phone Number"
                  placeHolder1="Your Email"
                  placeHolder2="Your Phone Number"
                  name1="email"
                  name2="phone"
                />

                <TextInput
                  placeHolder="Address"
                  labelName="Address"
                  name="address"
                />

                <div className={mentorshipStyles.selectInputBox}>
                  <select name="gender" onChange={(e) => e.target.value}>
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>

                <TextArea placeHolder="Your Brief Biography" name="bio" />

                <div className={mentorshipStyles.head2}>
                  <InputFlex
                    labelName1="Bank Name"
                    labelName2="Account Name"
                    placeHolder1="Bank Name"
                    placeHolder2="Account Name"
                    name1="bankName"
                    name2="acctName"
                  />
                </div>

                <div className={mentorshipStyles.selectInputBox}>
                  <TextInput
                    placeHolder="Account Number"
                    labelName="Account Number"
                    name="acctNum"
                  />
                  <TextInput
                    placeHolder="iDoff Digital Business Link"
                    labelName="Your iDoff Digital Business Profile(Contact Admin if you don't
                  have one)"
                    name="idoff"
                  />

                  <InputFlex
                    labelName1="Facebook Link"
                    labelName2="Instagram Link"
                    placeHolder1="Facebook Link"
                    placeHolder2="Instagram Link"
                    name1="fLink"
                    name2="iLink"
                  />
                  <InputFlex
                    labelName1="Twitter Link"
                    labelName2="TikTok Link"
                    placeHolder1="Twitter Link"
                    placeHolder2="TikTok Link"
                    name1="tLink"
                    name2="tiLink"
                  />
                  <select name="skill" onChange={(e) => e.target.value}>
                    <option value="">Your Skills and Specializations </option>
                    <option value="Acting">Acting</option>
                    <option value="Video Editing">Video Editing</option>
                    <option value="Screen Writing">Screen Writing</option>
                    <option value="Drone Piloting">Drone Piloting</option>
                    <option value="Cinematography">Cinematography</option>
                    <option value="Computer Appreciation">
                      Computer Appreciation
                    </option>
                    <option value="Film Making and Production Management">
                      Film Making and Production Management
                    </option>
                  </select>
                </div>

                <div>
                  <p>
                    Will you be available for auditions, projects and travel for
                    work?
                  </p>
                  <div className={mentorshipStyles.radioType}>
                    <div className={mentorshipStyles.radi1}>
                      <label>Yes</label>
                      <input
                        type="Radio"
                        name="type"
                        value="Yes"
                        onChange={() => console.log("")}
                      />
                    </div>

                    <div className={mentorshipStyles.radi2}>
                      <label>No</label>
                      <input
                        type="Radio"
                        name="type"
                        value="No"
                        onChange={() => console.log("")}
                      />
                    </div>
                  </div>
                </div>

                <TextInput
                  placeHolder="(E.g. English, Igbo, Hausa, Yoruba, French)"
                  labelName="Languages Spoken"
                  name="lang"
                />

                <TextInput
                  placeHolder="( Eg. Swimming, Dancing, Rap)"
                  labelName="Sports or Physical abilities"
                  name="sport"
                />

                <input
                  type="file"
                  hidden
                  ref={fileRef}
                  onChange={selectProfilePic}
                  name="file"
                />

                <div
                  className={mentorshipStyles.choosePix}
                  onClick={selecProfilePix}
                >
                  <IoCameraOutline className={mentorshipStyles.icon} />
                  <span>Upload Studio Headshot</span>
                </div>

                {img !== "" ? (
                  <div className={mentorshipStyles.profilePixBox}>
                    <img src={img} alt="img" />
                  </div>
                ) : null}

                <div className={mentorshipStyles.checkbx}>
                  <input
                    type="checkbox"
                    name="agree"
                    onChange={setCheckAgreement}
                  />
                  <p>
                    By ticking this box, you authorize us to use the above
                    correct information for promotional purposes and agrees to
                    the policies , terms and conditions of this website.
                  </p>
                </div>
                <ButtonPrimary btnValue="Create Profile" />
              </form>
            </div>
          ) : null}

          {!isPay ? (
            <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => (
                <button
                  className={mentorshipStyles.button}
                  onClick={() => {
                    if (auth) {
                      initializePayment(handleSuccess, handleClose);
                    } else {
                      navigate("/signup");
                    }
                  }}
                >
                  Pay To Become Member
                </button>
              )}
            </PaystackConsumer>
          ) : null}
        </div>
      </div>

      <Footer />
      <ToastContainer theme="light" />
      {loading ? <Preloader /> : null}

      {isModal ? (
        <div>
          <div
            className={mentorshipStyles.modalBox}
            onClick={() => setIsmodal(false)}
          ></div>
          <div className={mentorshipStyles.modalBoxContent}>
            <h2>Edit Profile</h2>

            <form onSubmit={handleEditProfile}>
              <div className={mentorshipStyles.formMain}>
                <div className={mentorshipStyles.formMainInput}>
                  <label>Full Name</label>
                  <input placeholder="Full Name" ref={fullName} name="fName" />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Email Address</label>
                  <input placeholder="Email Address" name="email" ref={email} />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Phone Number</label>
                  <input placeholder="Phone Number" name="phone" ref={phone} />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Address</label>
                  <input placeholder="Address" name="address" ref={address} />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <select
                    name="gender"
                    onChange={(e) => e.target.value}
                    ref={gender}
                  >
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <textarea
                    placeholder="Your Biography"
                    name="bio"
                    ref={bio}
                  ></textarea>
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Bank Name</label>
                  <input
                    placeholder="Bank Name"
                    name="bankName"
                    ref={bankName}
                  />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Account Name</label>
                  <input
                    placeholder="Account Name"
                    name="acctName"
                    ref={acctName}
                  />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Account Number</label>
                  <input
                    placeholder="Account Number"
                    name="acctNum"
                    ref={acctNum}
                  />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Facebook Link</label>
                  <input placeholder="Facebook Link" name="fLink" ref={fLink} />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Instagram Link</label>
                  <input
                    placeholder="Instagram Link"
                    name="iLink"
                    ref={iLink}
                  />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <label>Twitter Link</label>
                  <input placeholder="Twitter Link" name="tLink" ref={tLink} />
                </div>
                <div className={mentorshipStyles.formMainInput}>
                  <label>Tiktok Link</label>
                  <input placeholder="Tiktok Link" name="tiLink" ref={tiLink} />
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <select name="skill" ref={skill}>
                    <option value="">Your Skills and Specializations </option>
                    <option value="Acting">Acting</option>
                    <option value="Video Editing">Video Editing</option>
                    <option value="Screen Writing">Screen Writing</option>
                    <option value="Drone Piloting">Drone Piloting</option>
                    <option value="Cinematography">Cinematography</option>
                    <option value="Computer Appreciation">
                      Computer Appreciation
                    </option>
                    <option value="Film Making and Production Management">
                      Film Making and Production Management
                    </option>
                  </select>
                </div>

                <div className={mentorshipStyles.formMainInput}>
                  <p>
                    Will you be available for auditions, projects and travel for
                    work?
                  </p>
                  <div className={mentorshipStyles.radioType}>
                    <div className={mentorshipStyles.radioText}>
                      <label>Yes</label>
                      <input
                        type="Radio"
                        name="type"
                        value="Yes"
                        ref={typeYes}
                        onChange={() => console.log("")}
                      />
                    </div>

                    <div className={mentorshipStyles.radioText2}>
                      <label>No</label>
                      <input
                        type="Radio"
                        name="type"
                        value="No"
                        ref={typeNo}
                        onChange={() => console.log("")}
                      />
                    </div>
                  </div>

                  <div className={mentorshipStyles.formMainInput}>
                    <label>Languages Spoken</label>
                    <input
                      placeholder="Languages Spoken"
                      name="lang"
                      ref={lang}
                    />
                  </div>

                  <div className={mentorshipStyles.formMainInput}>
                    <label>Sports or Physical abilities</label>
                    <input
                      placeholder="Sports or Physical abilities"
                      name="sport"
                      ref={sport}
                    />
                  </div>

                  <div className={mentorshipStyles.formMainInput}>
                    <label>
                      Your iDoff Digital Business Profile(Contact Admin if you
                      don't have one
                    </label>
                    <input
                      placeholder="iDoff Digital Smart Profile"
                      name="idoff"
                      ref={idoff}
                    />
                  </div>

                  <input
                    type="file"
                    hidden
                    ref={fileRef}
                    onChange={selectProfilePic}
                    name="file"
                  />

                  <div
                    className={mentorshipStyles.choosePix}
                    onClick={selecProfilePix}
                  >
                    <IoCameraOutline className={mentorshipStyles.icon} />
                    <span>Upload Studio Headshot</span>
                  </div>

                  {img !== "" ? (
                    <div className={mentorshipStyles.profilePixBox}>
                      <img
                        src={
                          img.includes("IMG-")
                            ? `${baseUrl}/api/uploads/${img}`
                            : img
                        }
                        alt="img"
                      />
                    </div>
                  ) : null}
                </div>

                <ButtonPrimary btnValue="Save Changes" />
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {sub ? (
        <div>
          <div
            className={mentorshipStyles.modal}
            onClick={() => setSub(false)}
          ></div>
          <div className={mentorshipStyles.modalContent}>
            <h2>Subscribe to our Youtube Channel</h2>
            <p>To get interesting Media,Tech and Lifestyle Contents</p>
            <div
              className={mentorshipStyles.imgBox}
              onClick={handleSubscription}
            >
              <img src={youtubeImg} alt="Youtube Img" />
            </div>

            <div className={mentorshipStyles.btnBoxSub}>
              <button
                className={mentorshipStyles.btnBoxSub1}
                onClick={handleSubscription}
              >
                Subscribe Now
              </button>
              <button onClick={() => setSub(false)}>Subscribe Later</button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Membership;
