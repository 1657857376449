import React, { useEffect, useState } from "react";
import solutionStyles from "./Solutions.module.css";
import brandImg from "../../assets/brandImg.png";
import shakeImg from "../../assets/shake.png";
import eImg from "../../assets/earn.png";
import $ from "jquery";
import { baseUrl } from "../../utilities/apiUrl";
import ShimmerSingle from "../../components/ShimmerSingle/ShimmerSingle";

const Solutions = () => {
  const [dataItems, setDataItems] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getSolutions.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.solutions);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);
  return (
    <div className={solutionStyles.solutionBox}>
      <div className={solutionStyles.boxMain1}>
        <div className={solutionStyles.box1}>
          <img src={brandImg} alt="Icon" width="45" />
        </div>
        <h2>BECOME A BRAND</h2>
        {dataItems.length > 0 ? <p>{dataItems[0].item}</p> : <ShimmerSingle />}
      </div>

      <div className={solutionStyles.boxMain1}>
        <div className={solutionStyles.box1}>
          <img src={shakeImg} alt="Icon" width="45" />
        </div>
        <h2>NETWORK TO CONNECT</h2>
        {dataItems.length > 0 ? <p>{dataItems[1].item}</p> : <ShimmerSingle />}
      </div>

      <div className={solutionStyles.boxMain1}>
        <div className={solutionStyles.box1}>
          <img src={eImg} alt="Icon" />
        </div>
        <h2>EARN INCOME</h2>
        {dataItems.length > 0 ? <p>{dataItems[2].item}</p> : <ShimmerSingle />}
      </div>
    </div>
  );
};

export default Solutions;
