import React from "react";
import courseSampleStyles from "./CourseSampleCard.module.css";
import { FaPlay } from "react-icons/fa";
import { baseUrl } from "../../utilities/apiUrl";

const CourseSampleCard = (props) => {
  return (
    <div
      className={courseSampleStyles.courseSampleCard}
      onClick={props.cardAction}
    >
      <div>
        <img
          src={`${baseUrl}/api/uploads/${props.courseImg}`}
          alt="Course-Img"
        />
      </div>

      <div className={courseSampleStyles.courseSampleMainText}>
        <h3>{props.courseName}</h3>
        <p>{props.courseDescription}</p>
      </div>

      {props.showBtn === true ? (
        <div className={courseSampleStyles.btnBox}>
          <button className={courseSampleStyles.btnBox1}>Buy</button>
          <button className={courseSampleStyles.btnBox2}>
            <FaPlay className={courseSampleStyles.btnIcon} />
            Preview
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CourseSampleCard;
