import React, { useEffect, useState } from "react";
import previewStyles from "./Preview.module.css";
import CourseSampleCard from "../../../components/CourseSampleCard/CourseSampleCard";
import { useNavigate } from "react-router-dom";
import Preloader from "../../../components/Preloader/Preloader";
import { baseUrl } from "../../../utilities/apiUrl";
import $ from "jquery";

const Preview = () => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "GET",
      url: `${baseUrl}/api/getCourses.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setCourses(data.courses);
          setIsLoading(false);
          setShow(true);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  const gotoPreview = (id, name) => {
    let name2 = name.replace(/\s/g, "-");
    navigate(`/course?id=${id}&name=${name2}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className={previewStyles.courseMain}>
      <h2>Join The Class</h2>
      <div className={previewStyles.courseCardBox}>
        {show
          ? courses.map((course, index) => {
              return (
                <div key={course.id}>
                  <CourseSampleCard
                    courseImg={course.thumbnail}
                    courseName={course.course_name}
                    courseDescription={course.course_desc1}
                    cardAction={() =>
                      gotoPreview(course.id, course.course_name)
                    }
                  />
                </div>
              );
            })
          : null}
      </div>

      {loading ? <Preloader /> : null}
    </div>
  );
};

export default Preview;
