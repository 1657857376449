import React, { useState, useEffect } from "react";
import navStyles from "../Nav/Nav.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoLockClosed } from "react-icons/io5";
import { CgMenuOreos } from "react-icons/cg";
import AOS from "aos";
import "aos/dist/aos.css";

const Nav2 = (props) => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const handleGoToLogin = () => navigate("/admin");
  const handleLogout = () => {
    localStorage.removeItem("isAdmin");
    navigate("/admin");
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const closeShowMenu = () => {
    setShowMenu(false);
  };
  return (
    <div>
      <nav>
        <ul className={navStyles.navbar}>
          <li>
            <Link to="/dashboard">Home</Link>
          </li>
          <li>
            <Link to="/Course-list"> Courses</Link>
          </li>

          <li>
            <Link to="/movie-list">Movies </Link>
          </li>

          <li>
            <Link to="/store-list">Store Products</Link>
          </li>

          <li>
            <Link to="/artist-profiles">Artist Profiles</Link>
          </li>
          <li>
            <Link to="/testimonials">Testimonials</Link>
          </li>

          <li className={navStyles.btnNavBox}>
            {auth ? (
              <button className={navStyles.btnSignOut} onClick={handleLogout}>
                <IoLockClosed className={navStyles.lockIcon} />
                Sign out
              </button>
            ) : (
              <button className={navStyles.btnSignin} onClick={handleGoToLogin}>
                Sign in
              </button>
            )}
          </li>

          <CgMenuOreos
            className={navStyles.menuIcon}
            onClick={handleShowMenu}
          />
        </ul>
      </nav>

      {showMenu ? (
        <>
          <div
            className={navStyles.menuContentBox}
            onClick={closeShowMenu}
          ></div>

          <div className={navStyles.menuContent} data-aos="fade-left">
            <div className={navStyles.menuTexth2}>
              <h2>Menu</h2>
            </div>

            <div className={navStyles.menuNav}>
              <div className={navStyles.menuNavItems}>
                <Link to="/dashboard">Home</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/Course-list">Courses</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/movie-list">Movies</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/store-list">Store Products</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/artist-profiles">Artist Profiles</Link>
              </div>
              <div className={navStyles.menuNavItems}>
                <Link to="/testimonials">Testimonials</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                {auth ? (
                  <button
                    className={navStyles.btnSignOut}
                    onClick={handleLogout}
                  >
                    <IoLockClosed className={navStyles.lockIcon} />
                    Sign out
                  </button>
                ) : (
                  <button
                    className={navStyles.btnSignin2}
                    onClick={handleGoToLogin}
                  >
                    Sign in
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Nav2;
