import React, { useState, useEffect, useRef } from "react";
import storeProdStyles from "./StoreProductView.module.css";
import Header from "../Header/Header";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import $ from "jquery";
import { baseUrl } from "../../utilities/apiUrl";
import Preloader from "../Preloader/Preloader";
import Footer from "../Footer/Footer";
import { convertPrice } from "../../utilities/convertPrice";
import { ToastContainer, toast } from "react-toastify";
import { PaystackConsumer } from "react-paystack";
import { titleUpdater } from "../../utilities/titleUpdater";

const StoreProductView = () => {
  titleUpdater("Store Product");
  const apiKey = process.env.REACT_APP_API_KEY;
  const dataInfo = JSON.parse(localStorage.getItem("info"));
  const [loading, setloading] = useState(true);
  const [product, setProduct] = useState([]);
  const [count, setCount] = useState(1);
  const [billInfo, setbillInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSum, setShowSum] = useState(false);
  const id = window.location.href.split("?id=")[1];
  const fName = useRef();
  const email = useRef();
  const phone = useRef();
  const address = useRef();
  const prodId = id.split("&")[0];
  let info = {};

  const config = {
    reference: new Date().getTime().toString(),
    email: dataInfo ? dataInfo.email : "",
    amount: product ? count * Number(product.prodprice) * 100 : 0,
    publicKey: apiKey,
  };
  const customId = "id-static";
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const success = (msg) =>
    toast.success(msg, {
      toastId: customId,
      position: "top-center",
    });
  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getProduct.php`,
      data: { id: prodId },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setProduct(data);
          setloading(false);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, []);

  const increaseQuantity = () => {
    setCount(count + 1);
  };
  const decreaseQuantity = () => {
    setCount(count - 1);
  };

  const handleBuyProduct = () => {
    setShowModal(true);
    setbillInfo(true);
    setloading(true);
    const info = JSON.parse(localStorage.getItem("info"));

    if (info) {
      setTimeout(() => {
        fName.current.value = info.fullname;
        email.current.value = info.email;
        phone.current.value = info.phone;
        address.current.value = info.address;
        setloading(false);
      }, 500);
    } else {
      setloading(false);
    }
  };

  const handleGoBack = () => {
    setloading(true);
    setbillInfo(true);
    setShowSum(false);
    const data = JSON.parse(localStorage.getItem("info"));
    setTimeout(() => {
      fName.current.value = data.fullname;
      email.current.value = data.email;
      phone.current.value = data.phone;
      address.current.value = data.address;
      setloading(false);
    }, 500);
  };

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    const fullname = e.target.elements.fullname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const address = e.target.elements.address.value;

    if (fullname === "") {
      notify("Full name required");
    } else if (email === "") {
      notify("Email required");
    } else if (phone === "") {
      notify("Phone number required");
    } else if (address === "") {
      notify("Address required");
    } else {
      info = {
        fullname: fullname,
        email: email,
        phone: phone,
        address: address,
      };
      localStorage.setItem("info", JSON.stringify(info));
      setbillInfo(false);
      setShowSum(true);
    }
  };

  const handleClose = () => {
    console.log("closed");
  };

  const handleSuccess = () => {
    setloading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/sendEmail.php`,
      data: {
        fullname: dataInfo.fullname,
        email: dataInfo.email,
        phone: dataInfo.phone,
        address: dataInfo.address,
        prodName: product.prodname,
        prodQuant: count,
        totalPrice: convertPrice(count * Number(product.prodprice)),
        link: window.location.href,
      },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setloading(false);
          setShowModal(false);
          setShowSum(false);
          setbillInfo(false);
          success(
            "We are currently Processing your order and our Admin team will contact you soon"
          );
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const componentProps = {
    ...config,
    text: "Subscribe to watch",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div>
      <Header />
      <div className={storeProdStyles.prodContainer}>
        <div className={storeProdStyles.prodImg}>
          <img src={`${baseUrl}/api/uploads/${product.prodImg}`} alt="Img" />
        </div>

        <div className={storeProdStyles.prodText}>
          <h2>{product.prodname}</h2>
          <p className={storeProdStyles.prodText1}>
            {convertPrice(product.prodprice)}
          </p>

          <div className={storeProdStyles.desc}>
            <p>{product.proddesc}</p>
          </div>

          <div className={storeProdStyles.btnBox}>
            <p>Select Quantity</p>
            <button onClick={decreaseQuantity}>-</button>
            <span>{count}</span>
            <button onClick={increaseQuantity}>+</button>
          </div>

          <ButtonPrimary btnValue="Buy Product" btnAction={handleBuyProduct} />
        </div>
      </div>

      {loading ? <Preloader /> : null}
      {showModal ? (
        <>
          <div
            className={storeProdStyles.modal}
            onClick={() => {
              setShowModal(false);
              setShowSum(false);
            }}
          ></div>
          <div className={storeProdStyles.modalContent}>
            {billInfo ? (
              <div className={storeProdStyles.billInfo}>
                <div className={storeProdStyles.billHeader}>
                  <h2>Billing information</h2>
                  <p>Please fill the form below to continue</p>
                </div>

                <form onSubmit={handleSubmitInfo}>
                  <div className={storeProdStyles.billForm}>
                    <label>Full Name</label>
                    <input
                      placeholder="Full Name"
                      name="fullname"
                      ref={fName}
                    />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Email Address</label>
                    <input
                      placeholder="Email Address"
                      name="email"
                      ref={email}
                    />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Phone Number</label>
                    <input
                      placeholder="Phone Number"
                      name="phone"
                      ref={phone}
                    />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Delivery Address</label>
                    <input
                      placeholder="Delivery Address"
                      name="address"
                      ref={address}
                    />
                  </div>

                  <ButtonPrimary btnValue="Continue" />
                </form>
              </div>
            ) : null}

            {showSum ? (
              <div className={storeProdStyles.orderSumBox}>
                <p className={storeProdStyles.backBtn} onClick={handleGoBack}>
                  Back
                </p>
                <h2>Order Summary</h2>

                <div className={storeProdStyles.orderMain}>
                  <div>
                    <img
                      src={`${baseUrl}/api/uploads/${product.prodImg}`}
                      alt="Img"
                    />
                  </div>

                  <div className={storeProdStyles.orderText}>
                    <p className={storeProdStyles.orderText1}>
                      {product.prodname}
                    </p>
                    <p className={storeProdStyles.orderText2}>
                      {convertPrice(product.prodprice)}
                    </p>
                    <p className={storeProdStyles.orderText3}>
                      Quantity: {count}
                    </p>
                    <p className={storeProdStyles.orderText3}>
                      Total Price:{" "}
                      {convertPrice(count * Number(product.prodprice))}
                    </p>
                  </div>
                </div>

                <PaystackConsumer {...componentProps}>
                  {({ initializePayment }) => (
                    <ButtonPrimary
                      btnValue="Pay Now"
                      btnAction={() => {
                        initializePayment(handleSuccess, handleClose);
                      }}
                    />
                  )}
                </PaystackConsumer>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <Footer />
      <ToastContainer theme="light" autoClose={30000} />
    </div>
  );
};

export default StoreProductView;
