import React, { useEffect, useState } from "react";
import headerStyles from "../Header/Header.module.css";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import $ from "jquery";

const Header = (props) => {
  const [userData, setUserData] = useState({});
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const email = localStorage.getItem("em");
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/getuser.php`,
        data: { email },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            console.log(data.error);
          } else {
            setIsUser(true);
            setUserData(data);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  }, []);

  return (
    <div className={headerStyles.header}>
      <Link to="/" className={headerStyles.anchor}>
        <div className={headerStyles.logoText}>
          <h2 className={headerStyles.headerH2}>MAGNUS</h2>
          <p className={headerStyles.headerP}>
            MEDIA <span>ACADEMY</span>
          </p>
        </div>
      </Link>

      <Nav data={userData} isUser={isUser} />
    </div>
  );
};

export default Header;
