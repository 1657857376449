import React, { useEffect, useState } from "react";
import headerStyles from "../Header/Header.module.css";
import Nav2 from "./Nav2";
import { Link } from "react-router-dom";
const Header = (props) => {
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    const auth = localStorage.getItem("isAdmin");
    if (auth) {
      setIsUser(true);
    }
  }, []);

  return (
    <div className={headerStyles.header}>
      <Link to="/" className={headerStyles.anchor}>
        <div className={headerStyles.logoText}>
          <h2 className={headerStyles.headerH2}>MAGNUS</h2>
          <p className={headerStyles.headerP}>
            MEDIA <span>ACADEMY</span>
          </p>
        </div>
      </Link>

      <Nav2 isUser={isUser} />
    </div>
  );
};

export default Header;
