import React, { useState } from "react";
import forgotPassStyles from "./ForgotPassword.module.css";
import TextInput from "../../components/TextInput/TextInput";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../components/Preloader/Preloader";
import { baseUrl } from "../../utilities/apiUrl";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const ForgotPassword = () => {
  titleUpdater("Forgot Password");
  const customId = "id-static";
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const success = (msg) =>
    toast.success(msg, {
      toastId: customId,
    });

  const [loading, setLoading] = useState(false);

  const handleResetPassword = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    if (event.target.email.value === "") {
      notify("Email Address Required");
    } else {
      setLoading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/forgotPassword.php`,
        data: { email: email },
      })
        .then(function (res) {
          setLoading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            success("Check your mail we have sent a reset password link");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  return (
    <div className={forgotPassStyles.formBox}>
      <form
        className={forgotPassStyles.formContainer}
        onSubmit={handleResetPassword}
      >
        <h2>Forgot Password?</h2>
        <p>Reset/change your password if forgotten</p>

        <TextInput
          placeHolder="Enter Email Address"
          type="text"
          labelName="Email Address"
          name="email"
        />

        <ButtonPrimary btnValue="Reset Password" />
      </form>

      <ToastContainer theme="light" />
      {loading ? <Preloader /> : null}
    </div>
  );
};

export default ForgotPassword;
