import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import { convertPrice } from "../../utilities/convertPrice";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import Preloader from "../../components/Preloader/Preloader";
import $ from "jquery";

const Courses = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [learnItems, setLearnItems] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [courseNameVal, setCourseNameVal] = useState("");
  const [courseImg, courseImg2] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [showModal4, setShowModal4] = useState(false);
  const [youVideos, setYouVideos] = useState([]);
  const [id2, setId] = useState("");
  const [id3, setId3] = useState("");
  const [id4, setId4] = useState("");
  const fileRef2 = useRef();
  const courseName = useRef();
  const coursePrice = useRef();
  const shortDesc2 = useRef();
  const fullDesc = useRef();
  const itemRef = useRef();
  const type2 = useRef();

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  const success = (msg) => {
    toast.success(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getCourses.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setCourses(data.courses);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    if (id2) {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/getLearnText.php`,
        data: { id: id2 },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            console.log(data.error);
          } else {
            setLearnItems(data.items);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
    // eslint-disable-next-line
  }, [isRender]);

  const editCourseHandler = (
    id,
    name,
    price,
    shortDesc,
    fulldesc,
    thubmbNail
  ) => {
    setShowModal1(true);
    setTimeout(() => {
      courseName.current.value = name;
      coursePrice.current.value = price;
      shortDesc2.current.value = shortDesc;
      fullDesc.current.value = fulldesc;
      setId(id);
      setimgUrl(thubmbNail);
    }, 500);
  };

  const handleSubmitHandler = (e) => {
    e.preventDefault();

    const CName = e.target.elements.CName.value;
    const CPrice = e.target.elements.CPrice.value;
    const Cdesc = e.target.elements.Cdesc.value;
    const Fdesc = e.target.elements.Fdesc.value;
    if (CName === "") {
      notify("Course Name required");
    } else if (CPrice === "") {
      notify("Course Price required");
    } else if (Cdesc === "") {
      notify("Short Description required");
    } else if (Fdesc === "") {
      notify("Full Description required");
    } else {
      const formData = new FormData();
      const file = fileRef2.current.files[0];
      formData.append("id", id2);
      formData.append("courseName", CName);
      formData.append("coursePrice", CPrice);
      formData.append("shortDesc", Cdesc);
      formData.append("fullDesc", Fdesc);
      imgUrl.includes("IMG")
        ? formData.append("image", imgUrl)
        : formData.append("file", file);
      setisloading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/UpdateCourseFile.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("Course updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleUploadVideo = (id, courseName, img) => {
    setisloading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getCourseVideos.php`,
      data: { id: id },
    })
      .then(function (res) {
        setisloading(false);
        setShowModal2(true);
        setCourseNameVal(courseName);
        setId(id);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setYouVideos(data.videos);
          courseImg2(img);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const handleUploadVideoSubmit = (e) => {
    e.preventDefault();
    const typeVid = e.target.typeVid.value;
    const VideoID = e.target.VideoID.value;

    if (typeVid === "") {
      notify("Video type required (e.g intro, Episode 1, Episode2)");
    } else if (VideoID === "") {
      notify("Youtube Video ID required");
    } else {
      setisloading(true);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/uploadCourseVideos.php`,
        data: { courseId: id2, typeVid: typeVid, VideoID: VideoID },
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal2(false);
            window.location.reload();
            success("Course Video Updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleWhatToLearnShow = (id, courseName) => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getLearnText.php`,
      data: { id: id },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setLearnItems(data.items);
          setShowModal3(true);
          setCourseNameVal(courseName);
          setId(id);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const handleAddText = (e) => {
    e.preventDefault();
    const item = e.target.item.value;

    if (item === "") {
      notify("Add what to learn");
    } else {
      const formData = new FormData();
      formData.append("id", id2);
      formData.append("courseName", courseNameVal);
      formData.append("item", item);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/AddLearnText.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal2(false);
            e.target.item.value = "";
            success("Added Sucessfully");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const deleteItem = (id) => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/deleteItemLearn.php`,
      data: { id: id },
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setIsRender(!isRender);
          success("Item Deleted Sucessfully");
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const deleteCourseVideos = (id) => {
    setisloading(true);

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/deleteCourseVideo.php`,
      data: { id: id },
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setIsRender(!isRender);
          window.location.reload();
          setShowModal2(false);
          success("Course video deleted");
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const handleEdit = (id, item) => {
    setShowModal4(true);
    setId3(id);
    setTimeout(() => {
      itemRef.current.value = item;
    }, 500);
  };

  const handleAddTextEdit = (e) => {
    e.preventDefault();
    const item = e.target.item2.value;

    if (item === "") {
      notify("Learn text required");
    } else {
      const formData = new FormData();
      formData.append("id", id3);
      formData.append("item", item);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/UpdateLearnText.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);

          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal2(false);
            e.target.item2.value = "";
            success("Updated Sucessfully");
            setShowModal4(false);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const editCourseVideoHandler1 = (id, type) => {
    setShowModal5(true);
    setTimeout(() => {
      type2.current.value = type;
      setId4(id);
    }, 500);
  };

  const handleEditYoutubeVideo2 = (e) => {
    e.preventDefault();
    const vidType1 = e.target.vidType1.value;
    const yType1 = e.target.yType1.value;

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/UpdateCourseVidEdit.php`,
      data: { id: id4, type: vidType1, videoName: yType1 },
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setIsRender(!isRender);
          window.location.reload();
          setShowModal2(false);
          setShowModal5(false);
          success("Course video deleted");
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh3}>
            <h2>Courses</h2>
          </div>

          {courses.length > 0
            ? courses.map((course, index) => {
                return (
                  <div className={adminStyles.mainBox4} key={index}>
                    <div className={adminStyles.imgBox4}>
                      <img
                        src={`${baseUrl}/api/uploads/${course.thumbnail}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Course Name</p>
                      <span>{course.course_name}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Course Price</p>
                      <span>{convertPrice(course.course_price)}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Short Description</p>
                      <span>{course.course_desc1}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Full Description</p>
                      <span>{course.course_description}</span>
                    </div>

                    <div className={adminStyles.boxBtn1}>
                      <button
                        onClick={() =>
                          editCourseHandler(
                            course.id,
                            course.course_name,
                            course.course_price,
                            course.course_desc1,
                            course.course_description,
                            course.thumbnail
                          )
                        }
                      >
                        Edit Course
                      </button>
                      <button
                        className={adminStyles.boxBtn3}
                        onClick={() =>
                          handleUploadVideo(
                            course.id,
                            course.course_name,
                            course.thumbnail
                          )
                        }
                      >
                        Upload Course Video
                      </button>
                      <button
                        onClick={() =>
                          handleWhatToLearnShow(course.id, course.course_name)
                        }
                      >
                        Add what to Learn
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <Footer />

      {showModal1 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal1(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Course</p>

            <form onSubmit={handleSubmitHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Course Name</label>
                <input
                  placeholder="Course Name"
                  ref={courseName}
                  name="CName"
                />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Course Price</label>
                <input
                  placeholder="Course Price"
                  ref={coursePrice}
                  name="CPrice"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Short Description</label>
                <input
                  placeholder="Short Description"
                  ref={shortDesc2}
                  name="Cdesc"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Full Description</label>
                <textarea
                  placeholder="Full Description"
                  name="Fdesc"
                  ref={fullDesc}
                ></textarea>
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Edit Thumbnail</label>
                <input
                  placeholder="Short Description"
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl.includes("IMG") ? (
                  <img src={`${baseUrl}/api/uploads/${imgUrl}`} alt="img" />
                ) : (
                  <img src={imgUrl} alt="img" />
                )}
              </div>

              <ButtonPrimary btnValue="Save Changes" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal2 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => {
              setShowModal2(false);
              setYouVideos([]);
            }}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Upload {courseNameVal} Course Video</p>

            <form onSubmit={handleUploadVideoSubmit}>
              <div className={adminStyles.formMainInput}>
                <label>Video Type</label>
                <input
                  placeholder="Video Type (intro, Episode 1, Episode 2)"
                  name="typeVid"
                />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Youtube Video ID</label>
                <input ceholder="Youtube Video ID" name="VideoID" />
              </div>
              <ButtonPrimary btnValue="Upload" />
            </form>
            <div className={adminStyles.cardform2Vid}>
              <h2>Course Videos</h2>

              <div className={adminStyles.formCourseVideos}>
                {youVideos.length > 0
                  ? youVideos.map((item, index) => {
                      return (
                        <div key={index} className={adminStyles.courseVid2Card}>
                          <div className={adminStyles.courseCardFile}>
                            <span
                              onClick={() =>
                                editCourseVideoHandler1(item.id, item.type)
                              }
                            >
                              Edit
                            </span>
                            <span onClick={() => deleteCourseVideos(item.id)}>
                              Delete
                            </span>
                          </div>
                          <div
                            className={adminStyles.VideoYoutubeBox2}
                            key={index}
                          >
                            <iframe
                              className={adminStyles.iframeStyle}
                              title="Youtube"
                              aria-hidden="true"
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              webkitallowfullscreen="true"
                              mozallowfullscreen="true"
                              srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${item.video_name}?autoplay=1>
                        <img src=${baseUrl}/api/uploads/${courseImg} alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a></style>`}
                            ></iframe>
                          </div>

                          <h3>{item.type}</h3>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showModal3 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal3(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Add What to Learn for {courseNameVal}</p>

            <form onSubmit={handleAddText}>
              <div className={adminStyles.formMainInput}>
                <label>What to Learn</label>
                <input
                  placeholder="e.g Understanding Film Making"
                  name="item"
                />
              </div>

              <ButtonPrimary btnValue="Add Text" />

              <div>
                <h4>What to Learn</h4>
                {learnItems.length > 0
                  ? learnItems.map((item, index) => {
                      return (
                        <div key={item.id} className={adminStyles.formText}>
                          <span>
                            {index + 1}. {item.item}
                          </span>

                          <div>
                            <button
                              type="button"
                              className={adminStyles.editBtn}
                              onClick={() => handleEdit(item.id, item.item)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className={adminStyles.deleteBtn}
                              onClick={() => deleteItem(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {showModal4 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal4(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit</p>

            <form onSubmit={handleAddTextEdit}>
              <div className={adminStyles.formMainInput}>
                <label>What to Learn</label>
                <input
                  placeholder="e.g Understanding Film Making"
                  name="item2"
                  ref={itemRef}
                />
              </div>

              <ButtonPrimary btnValue="Edit" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal5 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal5(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Course Video</p>

            <form onSubmit={handleEditYoutubeVideo2}>
              <div className={adminStyles.formMainInput}>
                <label>Video Type</label>
                <input
                  placeholder="Video Type (intro, Episode 1, Episode2"
                  name="vidType1"
                  ref={type2}
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>New Youtube ID</label>
                <input placeholder="New Youtube ID" name="yType1" />
              </div>

              <ButtonPrimary btnValue="Edit" />
            </form>
          </div>
        </div>
      ) : null}

      {isloading ? <Preloader /> : null}
      <ToastContainer theme="light" />
    </div>
  );
};

export default Courses;
