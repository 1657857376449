import React, { useState, useEffect, useRef } from "react";
import signUpStyles from "./SignUpPage.module.css";
import { ToastContainer, toast } from "react-toastify";
import TextInput from "../../components/TextInput/TextInput";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonTransparent from "../../components/ButtonTransparent/ButtonTransparent";
import { IoCameraOutline } from "react-icons/io5";
import Preloader from "../../components/Preloader/Preloader";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

const SignupPage = () => {
  titleUpdater("Sign Up");
  const navigate = useNavigate();
  const fileRef = useRef();
  const notify = (msg) => toast.error(msg);
  const [showNextForm, setShowNextForm] = useState(true);
  const [btnText, setBtnText] = useState("Continue");
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [hide, setHide] = useState("");
  const [hide2, setHide2] = useState(signUpStyles.hide);

  useEffect(() => {
    AOS.init();
  }, [hide, hide2]);

  const handleSignUpForm = (e) => {
    e.preventDefault();
    const fullName = e.target.fullName.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const dob = e.target.dob.value;
    const address = e.target.Address.value;
    const phone1 = e.target.phone1.value;
    const phone2 = e.target.phone2.value;
    const gender = e.target.gend.value;

    if (fullName === "") {
      notify("Full name is required");
    } else if (email === "") {
      notify("Email is required");
    } else if (password === "") {
      notify("Password is required");
    } else if (dob === "") {
      notify("Date Of Birth is required");
    } else if (address === "") {
      notify("Address is required");
    } else {
      setShowNextForm(false);
      setBtnText("Sign Up");
      setHide(signUpStyles.hide);
      setHide2("");
      if (phone1 === "") {
        notify("Phone Number is required");
      } else if (phone2 === "") {
        notify("Parent Phone Number is required");
      } else if (fileRef.current.files.length <= 0) {
        notify("Choose a Profile Picture");
      } else if (gender === "Gender") {
        notify("Select your gender");
      } else {
        setLoading(true);
        const formData = new FormData();
        formData.append("fullname", fullName);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("dob", dob);
        formData.append("address", address);
        formData.append("phoneNumber", phone1);
        formData.append("parentNumber", phone2);
        formData.append("gend", gender);
        formData.append("file", fileRef.current.files[0]);

        $.ajax({
          type: "POST",
          url: `${baseUrl}/api/signup.php`,
          processData: false,
          contentType: false,
          enctype: "multipart/form-data",
          data: formData,
        })
          .then(function (res) {
            setLoading(false);
            let data = JSON.parse(res);
            if (data.error) {
              notify(data.error);
            } else {
              localStorage.setItem("auth", true);
              localStorage.setItem("em", email);
              navigate(`/join_us`);
            }
          })
          .fail(function (res) {
            console.log(res);
          });
      }
    }
  };
  const selecProfilePix = () => {
    fileRef.current.click();
  };
  const selectProfilePic = () => {
    const profilePix = fileRef.current.files[0];
    let url = URL.createObjectURL(profilePix);
    setImg(url);
  };

  return (
    <div className={signUpStyles.formBox}>
      <div></div>
      <form className={signUpStyles.formContainer} onSubmit={handleSignUpForm}>
        <h2>Create Account</h2>
        <p>Please fill the Form below to continue</p>

        <div data-aos="fade-right" className={hide}>
          <TextInput
            placeHolder="Full Name"
            type="text"
            labelName="Full Name"
            name="fullName"
          />

          <TextInput
            placeHolder="Email Address"
            type="text"
            labelName="Email Address"
            name="email"
          />

          <TextInput
            placeHolder="Password"
            type="password"
            labelName="Password"
            name="password"
          />

          <TextInput
            placeHolder="Date Of Birth"
            type="date"
            labelName="Date Of Birth"
            name="dob"
          />

          <TextInput
            placeHolder="Address"
            type="text"
            labelName="Address"
            name="Address"
          />
        </div>

        <div data-aos="fade-left" className={hide2}>
          <TextInput
            placeHolder="Phone Number"
            type="number"
            labelName="Phone Number"
            name="phone1"
          />

          <TextInput
            placeHolder="Parent Phone Number"
            type="number"
            labelName="Parent Phone Number"
            name="phone2"
          />

          <input
            type="file"
            hidden
            ref={fileRef}
            onChange={selectProfilePic}
            name="file"
          />

          <div className={signUpStyles.choosePix} onClick={selecProfilePix}>
            <IoCameraOutline className={signUpStyles.icon} />
            <span>Choose Profile Picture </span>
          </div>

          {img !== "" ? (
            <div className={signUpStyles.profilePixBox}>
              <img src={img} alt="img" />
            </div>
          ) : null}

          <div className={signUpStyles.selectInputBox}>
            <select name="gend">
              <option value="Gender">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>

        <div className={signUpStyles.signBtnFlex}>
          {showNextForm === false ? (
            <button
              onClick={() => {
                setShowNextForm(true);
                setBtnText("Continue");
                setHide("");
                setHide2(signUpStyles.hide);
              }}
            >
              Back
            </button>
          ) : null}

          <ButtonPrimary btnValue={btnText} />
        </div>
        <ButtonTransparent
          linkName="Already have an account? Sign in"
          linkPath="/signin"
        />
      </form>

      <ToastContainer theme="light" autoClose={2000} />
      {loading ? <Preloader /> : null}
    </div>
  );
};

export default SignupPage;
