import React from "react";
import buttonPrimary from "./ButtonPrimary.module.css";

const ButtonPrimary = (props) => {
  return (
    <div className={buttonPrimary.btnBox}>
      <button onClick={props.btnAction}>{props.btnValue}</button>
    </div>
  );
};

export default ButtonPrimary;
