import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import Preloader from "../../components/Preloader/Preloader";
import $ from "jquery";

const TestimonialAdmin = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [dataItems, setDataItems] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [imgUrl, setimgUrl] = useState("");
  const [id2, setId] = useState("");
  const fileRef2 = useRef();
  const courseName = useRef();
  const coursePrice = useRef();

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  const success = (msg) => {
    toast.success(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getTestimonials.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.testimonials);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  const editCourseHandler = (id, name, remark, pix) => {
    setShowModal1(true);
    setTimeout(() => {
      courseName.current.value = name;
      coursePrice.current.value = remark;
      setId(id);
      setimgUrl(pix);
    }, 500);
  };

  const handleSubmitHandler = (e) => {
    e.preventDefault();

    const tName = e.target.elements.tName.value;
    const tRemark = e.target.elements.tRemark.value;

    if (tName === "") {
      notify("Name required");
    } else if (tRemark === "") {
      notify("Remark required");
    } else {
      const formData = new FormData();
      const file = fileRef2.current.files[0];
      formData.append("id", id2);
      formData.append("tName", tName);
      formData.append("tRemark", tRemark);
      imgUrl.includes("IMG")
        ? formData.append("image", imgUrl)
        : formData.append("file", file);
      setisloading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateTestimonials.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("Testimonial Updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh3}>
            <h2>Testimonials</h2>
          </div>

          {dataItems.length > 0
            ? dataItems.map((data, index) => {
                return (
                  <div className={adminStyles.mainBox4} key={index}>
                    <div className={adminStyles.imgBox4}>
                      <img
                        src={`${baseUrl}/api/uploads/${data.pic}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Name</p>
                      <span>{data.name}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Testimonial Remark</p>
                      <span>{data.remark}</span>
                    </div>

                    <div className={adminStyles.boxBtn1}>
                      <button
                        onClick={() =>
                          editCourseHandler(
                            data.id,
                            data.name,
                            data.remark,
                            data.pic
                          )
                        }
                      >
                        Edit Testimonial
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <Footer />

      {showModal1 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal1(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Testimonial</p>

            <form onSubmit={handleSubmitHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Name</label>
                <input placeholder="Name" ref={courseName} name="tName" />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Testimonial Remark</label>
                <input
                  placeholder="Testimonial Remark"
                  ref={coursePrice}
                  name="tRemark"
                />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Edit Testimonial Image</label>
                <input
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl.includes("IMG") ? (
                  <img src={`${baseUrl}/api/uploads/${imgUrl}`} alt="img" />
                ) : (
                  <img src={imgUrl} alt="img" />
                )}
              </div>

              <ButtonPrimary btnValue="Save Changes" />
            </form>
          </div>
        </div>
      ) : null}

      {isloading ? <Preloader /> : null}
      <ToastContainer theme="light" />
    </div>
  );
};

export default TestimonialAdmin;
