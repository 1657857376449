import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import joinUsStyles from "./JoinUsStyles.module.css";
import compCourse from "../../assets/comp.jpg";
import joinImg from "../../assets/join.jpg";
import mentorImg from "../../assets/ment.jpg";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import { titleUpdater } from "../../utilities/titleUpdater";
import Footer from "../../components/Footer/Footer";
import $ from "jquery";

const JoinUs = () => {
  titleUpdater(
    "Join Us Today | Your Path to Becoming A Media & Film Practitioner starts from here."
  );
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showNote1, setShowNote1] = useState(false);
  const [showNote2, setShowNote2] = useState(false);
  const [video, setVideo] = useState({});
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getHomeVideo.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setIsLoading(false);
          setVideo(data);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  const navigateToCourse = (courseName, id) => {
    navigate(`/course?id=${id}&name=${courseName}`);
    localStorage.setItem("courseName", courseName);
    localStorage.setItem("course_id", id);
    window.scrollTo(0, 0);
  };

  const goToMentorship = () => {
    navigate("/coaching");
    window.scrollTo(0, 0);
    localStorage.setItem("courseName", "coaching");
  };
  const showNotice1Handler = () => {
    setShowModal(true);
    setShowNote1(true);
    setShowNote2(false);
  };

  const showNotice2Handler = () => {
    setShowModal(true);
    setShowNote2(true);
    setShowNote1(false);
  };

  return (
    <div className={joinUsStyles.container}>
      <Header />

      {!isloading ? (
        <div className={joinUsStyles.cardVideo}>
          <video autoPlay={true} controls muted loop>
            <source
              src={`${baseUrl}/api/uploads/${video.video_name}`}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>
      ) : null}

      <div className={joinUsStyles.cardBox}>
        <div className={joinUsStyles.card}>
          <div className={joinUsStyles.cardStage1}>
            <h1>Stage 1</h1>
          </div>
          <div className={joinUsStyles.cardImg}>
            <img src={compCourse} alt="Membership Card" />
          </div>

          <div className={joinUsStyles.cardText}>
            <div className={joinUsStyles.cardText1}>
              <p className={joinUsStyles.cardP1}>COMPREHENSIVE COURSE</p>
              <p className={joinUsStyles.cardP2}>
                Select and undertake one or more of our online course(s) before
                you move to Stage 2 for live coaching & mentorship preparing you
                to visit us for practical projects.
              </p>
            </div>

            <div className={joinUsStyles.eachBox}>
              <span>Each Course</span>
              <span>N40,000</span>
            </div>

            <div className={joinUsStyles.cardCourse}>
              <div className={joinUsStyles.joinLinkBox}>
                <span onClick={() => navigateToCourse("Acting", 1)}>
                  Apply for Acting
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span onClick={() => navigateToCourse("Video-Editing", 2)}>
                  Apply for Video Editing
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span onClick={() => navigateToCourse("Screen-Writing", 3)}>
                  Apply for Screen Writing
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span onClick={() => navigateToCourse("Drone-Piloting", 4)}>
                  Apply for Drone Piloting
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span onClick={() => navigateToCourse("Cinematography", 5)}>
                  Apply for Cinematography
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span
                  onClick={() =>
                    navigateToCourse("Skit-Making-Content-Creation", 6)
                  }
                >
                  Apply for Skit Making / Content Creation
                </span>
              </div>

              <div className={joinUsStyles.joinLinkBox}>
                <span
                  onClick={() =>
                    navigateToCourse("Film-Making-And-Production-Management", 7)
                  }
                >
                  Apply for Film Making and Production Management
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={joinUsStyles.card}>
          <div className={joinUsStyles.cardStage1}>
            <h1>Stage 2</h1>
          </div>
          <div className={joinUsStyles.cardImg}>
            <img src={mentorImg} alt="Membership Card" />
          </div>
          <div className={joinUsStyles.cardText}>
            <div className={joinUsStyles.cardText1}>
              <p className={joinUsStyles.cardP1}>MENTORSHIP/COACHING</p>
              <p className={joinUsStyles.cardP2}>
                Register for Online practical, One on One Live Stream Coaching
                with your instructor and opportunity to drop pressing questions
                and get answers. Once this stage is completed, you will be
                invited to camp for a film project and more.
              </p>
            </div>

            <div className={joinUsStyles.cardCourse2}>
              <p>- Meet & Greet (Networking)</p>
              <p>- Free Accommodation </p>
              <p>- Continental Breakfast</p>
              <p>- Work on a film project with your set</p>
              <p>- Professional Business Profile to elevate your brand</p>
            </div>

            <ButtonPrimary
              btnValue="Join Live"
              btnAction={showNotice1Handler}
            />
          </div>
        </div>

        <div className={joinUsStyles.card}>
          <div className={joinUsStyles.cardStage1}>
            <h1>Stage 3</h1>
          </div>
          <div className={joinUsStyles.cardImg}>
            <img src={joinImg} alt="Membership Card" />
          </div>

          <div className={joinUsStyles.cardText}>
            <div className={joinUsStyles.cardText1}>
              <p className={joinUsStyles.cardP1}>
                MEMBERSHIP INTO MAGNUS MEDIA PRODUCTION
              </p>
            </div>

            <div className={joinUsStyles.cardCourse2}>
              <p>- One Year Membership</p>
              <p>
                -As a member, you will be called upon to feature in our cinema
                movies, documentaries, music videos, event and media contracts
                and get PAID.-{" "}
              </p>
              <p>
                - We will assist you to register in any Creative Guild of your
                choice like AGN, DGN, AMP and connect you to industry
                practitioners.
              </p>
            </div>

            <ButtonPrimary
              btnValue="Become A Member"
              btnAction={showNotice2Handler}
            />
          </div>
        </div>
      </div>

      {showModal ? (
        <>
          <div
            className={joinUsStyles.modal}
            onClick={() => {
              setShowModal(false);
              setShowNote1(false);
              setShowNote2(false);
            }}
          ></div>
          <div className={joinUsStyles.modalContent}>
            {showNote1 ? (
              <div>
                <div className={joinUsStyles.noticeBox}>
                  <h2>Notice !!!</h2>
                </div>

                <div className={joinUsStyles.boxP}>
                  <p>
                    Please ensure you have gone through STAGE 1 (COMPREHENSIVE
                    COURSE) before Starting this STAGE 2 (Mentorship/Coaching)
                    This is very important as to enable you get the best out of
                    this Programme.
                  </p>
                </div>

                <div className={joinUsStyles.btnPs}>
                  <button
                    className={joinUsStyles.btn1}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setShowModal(false);
                      setShowNote1(false);
                    }}
                  >
                    Take Course
                  </button>
                  <button
                    className={joinUsStyles.btn2}
                    onClick={goToMentorship}
                  >
                    Continue
                  </button>
                </div>
              </div>
            ) : null}

            {showNote2 ? (
              <div>
                <div className={joinUsStyles.noticeBox}>
                  <h2>Notice !!!</h2>
                </div>

                <div className={joinUsStyles.boxP}>
                  <p>
                    Please ensure you have gone through STAGE 1 (COMPREHENSIVE
                    COURSE) and STAGE 2 (Mentorship/Coaching) before Moving to
                    STAGE 3 (Become A Member) This is very important as to
                    enable you get the best out of this Programme.
                  </p>
                </div>

                <div className={joinUsStyles.btnPs}>
                  <button
                    className={joinUsStyles.btn1}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setShowModal(false);
                      setShowNote1(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className={joinUsStyles.btn2}
                    onClick={() => {
                      navigate("/membership");
                      window.scrollTo(0, 0);
                      localStorage.setItem("courseName", "membership");
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      <Footer />
    </div>
  );
};

export default JoinUs;
