import React from "react";
import footerStyles from "./Footer.module.css";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import FooterLogo from "../../assets/footerlogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  let link1 = "";
  if (window.location.href.includes("course")) {
    const value = window.location.href.split("?id")[0];
    link1 = `${value}s`;
  } else {
    link1 = window.location.href;
  }
  const link = encodeURI(link1);
  const textMsg = encodeURIComponent(document.title);

  return (
    <div className={footerStyles.footerBox}>
      <div className={footerStyles.footerNav}>
        <div>
          <div className={footerStyles.nav}>
            <div>
              <Link to="/membership">Membership</Link>
            </div>

            <div>
              <Link to="/courses">Courses</Link>
            </div>
            <div>
              <Link to="/terms">Terms of Service</Link>
            </div>
            <div>
              <Link to="/store">Store</Link>
            </div>
          </div>
        </div>

        <div className={footerStyles.socialIcons}>
          <div>
            <a href="https://www.facebook.com/MagnusFilms">
              <FaFacebook className={footerStyles.socialIcon} />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/magnusmedia/">
              <FaInstagram className={footerStyles.socialIcon} />
            </a>
          </div>
          <div>
            <a href="https://twitter.com/MagnusFilmsNg">
              <FaXTwitter className={footerStyles.socialIcon} />
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/c/magnusmediatv">
              <FaYoutube className={footerStyles.socialIcon} />
            </a>
          </div>
        </div>
      </div>

      <div className={footerStyles.footerNav2}>
        <div className={footerStyles.socialIcons}>
          <p>Share </p>
          <div>
            <a href={`https://www.facebook.com/share.php?u=${link}`}>
              <FaFacebook className={footerStyles.socialIcon12} />
            </a>
          </div>
          <div>
            <a
              href={`https://x.com/share?&url=${link}&text=${textMsg}&hashtags=magnusfilmacademy`}
            >
              <FaXTwitter className={footerStyles.socialIcon12} />
            </a>
          </div>
          <div>
            <a href={`https://api.whatsapp.com/send?text=${link}`}>
              <FaWhatsapp className={footerStyles.socialIcon} />
            </a>
          </div>
        </div>

        <div>
          <div className={footerStyles.nav}>
            <a href="tel:+2348032684423">Call us +2348032684423 </a>
          </div>
        </div>
      </div>

      <div className={footerStyles.footerLogo}>
        <p>Powered by</p>
        <a href="https://www.magnusfilmacademy.com/">
          <img src={FooterLogo} alt="Footer-logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
