import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import Preloader from "../../components/Preloader/Preloader";
import { convertPrice } from "../../utilities/convertPrice";
import $ from "jquery";

const Store = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [dataItems, setDataItems] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [imgUrl, setimgUrl] = useState("");
  const [id2, setId] = useState("");
  const fileRef2 = useRef();
  const courseName = useRef();
  const coursePrice = useRef();
  const shortDesc2 = useRef();
  const fullDesc = useRef();

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  const success = (msg) => {
    toast.success(msg, {
      autoClose: 20000,
      toastId: "73883",
    });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getProducts.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.products);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  const editCourseHandler = (
    id,
    name,
    price,
    shortDesc,
    fulldesc,
    thubmbNail
  ) => {
    setShowModal1(true);
    setTimeout(() => {
      courseName.current.value = name;
      coursePrice.current.value = price;
      shortDesc2.current.value = shortDesc;
      fullDesc.current.value = fulldesc;
      setId(id);
      setimgUrl(thubmbNail);
    }, 500);
  };

  const handleSubmitHandler = (e) => {
    e.preventDefault();

    const CName = e.target.elements.CName.value;
    const CPrice = e.target.elements.CPrice.value;
    const Cdesc = e.target.elements.Cdesc.value;
    const Fdesc = e.target.elements.Fdesc.value;

    if (CName === "") {
      notify("Product Name required");
    } else if (CPrice === "") {
      notify("Product Price required");
    } else if (Cdesc === "") {
      notify("Short Description required");
    } else if (Fdesc === "") {
      notify("Full Description required");
    } else {
      const formData = new FormData();
      const file = fileRef2.current.files[0];
      formData.append("id", id2);
      formData.append("prodName", CName);
      formData.append("prodPrice", CPrice);
      formData.append("shortDesc", Cdesc);
      formData.append("fullDesc", Fdesc);
      imgUrl.includes("IMG")
        ? formData.append("image", imgUrl)
        : formData.append("file", file);
      setisloading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateStoreproducts.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("Course updated");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const InsetNewMovie = () => {
    setShowModal3(true);
  };

  const addNewMovieHandler = (e) => {
    e.preventDefault();
    const prodName = e.target.elements.mTitle.value;
    const prodPrice = e.target.elements.mDuration.value;
    const mShort = e.target.elements.mShort.value;
    const mFull = e.target.elements.mFull.value;
    if (prodName === "") {
      notify("Product Name required");
    } else if (prodPrice === "") {
      notify("Product Price required");
    } else if (mShort === "") {
      notify("Short Description required");
    } else if (mFull === "") {
      notify("Full Description required");
    } else if (e.target.file.files.length === 0) {
      notify("Product Image required");
    } else {
      const formData = new FormData();
      const file = e.target.file.files[0];
      formData.append("prodName", prodName);
      formData.append("prodPrice", prodPrice);
      formData.append("shortDesc", mShort);
      formData.append("fullDesc", mFull);
      formData.append("file", file);
      setisloading(true);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/addNewProduct.php`,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        data: formData,
      })
        .then(function (res) {
          setisloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal1(false);
            success("New Movie Added");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const deleteMovieHandler = (id) => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/deleteProduct.php`,
      data: { id: id },
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          setIsRender(!isRender);
          setShowModal1(false);
          success("Product deleted");
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };
  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh3}>
            <h2>Store Products</h2>
          </div>

          {dataItems.length > 0
            ? dataItems.map((data, index) => {
                return (
                  <div className={adminStyles.mainBox4} key={index}>
                    <div className={adminStyles.imgBox4}>
                      <img
                        src={`${baseUrl}/api/uploads/${data.prodImg}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Product Name</p>
                      <span>{data.prodname}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Product Price</p>
                      <span>{convertPrice(data.prodprice)}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Short Description</p>
                      <span>{data.shortDesc}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Full Description</p>
                      <span>{data.proddesc}</span>
                    </div>

                    <div className={adminStyles.boxBtn1}>
                      <button
                        onClick={() =>
                          editCourseHandler(
                            data.id,
                            data.prodname,
                            data.prodprice,
                            data.shortDesc,
                            data.proddesc,
                            data.prodImg
                          )
                        }
                      >
                        Edit Product
                      </button>

                      <button
                        className={adminStyles.boxBtn3}
                        onClick={() => deleteMovieHandler(data.id)}
                      >
                        Delete Product
                      </button>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.boxBtn1}>
          <button className={adminStyles.boxBtn3} onClick={InsetNewMovie}>
            Add New Product
          </button>
        </div>
      </div>
      <Footer />

      {showModal1 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal1(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Product</p>

            <form onSubmit={handleSubmitHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Product Name</label>
                <input
                  placeholder="Product Name"
                  ref={courseName}
                  name="CName"
                />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Product Price</label>
                <input
                  placeholder="Product Price"
                  ref={coursePrice}
                  name="CPrice"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Short Description</label>
                <input
                  placeholder="Short Description"
                  ref={shortDesc2}
                  name="Cdesc"
                />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Full Description</label>
                <textarea
                  placeholder="Full Description"
                  name="Fdesc"
                  ref={fullDesc}
                ></textarea>
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Edit Product Image</label>
                <input
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl.includes("IMG") ? (
                  <img src={`${baseUrl}/api/uploads/${imgUrl}`} alt="img" />
                ) : (
                  <img src={imgUrl} alt="img" />
                )}
              </div>

              <ButtonPrimary btnValue="Save Changes" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal3 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal3(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Add New Product</p>

            <form onSubmit={addNewMovieHandler}>
              <div className={adminStyles.formMainInput}>
                <label>Product Name</label>
                <input placeholder="Product Name" name="mTitle" />
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Product Price</label>
                <input placeholder="Product Price" name="mDuration" />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Short Description</label>
                <input placeholder="Short Description" name="mShort" />
              </div>
              <div className={adminStyles.formMainInput}>
                <label>Full Description</label>
                <textarea
                  placeholder="Full Description"
                  name="mFull"
                ></textarea>
              </div>

              <div className={adminStyles.formMainInput}>
                <label>Choose Product Image</label>
                <input
                  ref={fileRef2}
                  name="file"
                  type="file"
                  onChange={() => {
                    const profilePix = fileRef2.current.files[0];
                    if (fileRef2.current.files.length > 0) {
                      let url = URL.createObjectURL(profilePix);
                      setimgUrl(url);
                    }
                  }}
                />
              </div>

              <div className={adminStyles.imgBox4}>
                {imgUrl !== "" ? <img src={imgUrl} alt="img" /> : null}
              </div>

              <ButtonPrimary btnValue="Add Movie" />
            </form>
          </div>
        </div>
      ) : null}

      {isloading ? <Preloader /> : null}
      <ToastContainer theme="light" />
    </div>
  );
};

export default Store;
