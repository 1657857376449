import React from "react";
import textInputStyles from "./InputFlex.module.css";

const InputFlex = (props) => {
  return (
    <div className={textInputStyles.textInputFlex}>
      <div className={textInputStyles.box1Input}>
        <label>{props.labelName1}</label>
        <input
          className={textInputStyles.textInput1}
          placeholder={props.placeHolder1}
          type={props.type1}
          name={props.name1}
        />
      </div>

      <div>
        <label>{props.labelName2}</label>
        <input
          className={textInputStyles.textInput2}
          placeholder={props.placeHolder2}
          type={props.type2}
          name={props.name2}
        />
      </div>
    </div>
  );
};

export default InputFlex;
