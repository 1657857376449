import React from "react";
import buttonTransStyles from "./ButtonTransparent.module.css";
import { Link } from "react-router-dom";

const ButtonTransparent = (props) => {
  return (
    <div className={buttonTransStyles.btnBox}>
      <Link to={props.linkPath}>{props.linkName}</Link>
    </div>
  );
};

export default ButtonTransparent;
