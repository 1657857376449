import React from "react";
import storeCardStyles from "./StoreCard.module.css";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";

const StoreCard = (props) => {
  return (
    <div className={storeCardStyles.storeCard}>
      <div className={storeCardStyles.storeImg}>
        <img src={props.img} alt="img" />
      </div>
      <div className={storeCardStyles.storeText}>
        <h2>{props.prodName}</h2>
        <p>{props.prodPrice}</p>
      </div>

      <div className={storeCardStyles.storeDesc}>
        <p>{props.desc}</p>
      </div>

      <ButtonPrimary btnValue="View Product" btnAction={props.showProduct} />
    </div>
  );
};

export default StoreCard;
