import React, { useEffect, useState } from "react";
import mentorshipStyles from "./MentorShip.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Preloader from "../../components/Preloader/Preloader";
import { PaystackConsumer } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import coachImg from "../../assets/ment.jpg";
import TextInput from "../../components/TextInput/TextInput";
import InputFlex from "../InputFlex/InputFlex";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import TextArea from "../TextArea/TextArea";
import { ToastContainer, toast } from "react-toastify";
import youtubeImg from "../../assets/youtube.jpg";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const MentorShip = () => {
  titleUpdater(
    "Enjoy Transformative Coaching/Mentorship & prepare yourself for Exclusive Camp Experience"
  );
  const apiKey = process.env.REACT_APP_API_KEY;
  const em = localStorage.getItem("em");
  const isSub = localStorage.getItem("isSub");
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");
  const [loading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [isRender, setIsRender] = useState(false);
  const [sub, setSub] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const customId = "id-static";
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const success = (msg) =>
    toast.success(msg, {
      toastId: customId,
    });

  const config = {
    reference: new Date().getTime().toString(),
    email: auth ? em : "",
    amount: (60000 + 1000) * 100,
    publicKey: apiKey,
  };

  useEffect(() => {
    setIsLoading(true);

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getMentorPayment.php`,
      data: { email: em, check: "mentorship" },
    })
      .then(function (res) {
        setIsLoading(false);
        setTimeout(() => {
          if (isSub) {
            setSub(false);
          } else {
            setSub(true);
          }
        }, 1500);
        let data = JSON.parse(res);
        if (data.error) {
          if (data.error === "Payment expired") {
            notify("Payment Expired, Please check your mail");
          }
        } else {
          const value = data.payments.filter(
            (item, index) => item.email === em && item.pay_type === "mentorship"
          );
          if (value.length > 0) {
            setIsPay(true);
          } else {
            setIsPay(false);
          }
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getLinkVideo.php`,
    })
      .then(function (res) {
        setIsLoading(false);
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setLink(data);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  const handleSuccess = () => {
    setIsLoading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/mentorPayment.php`,
      data: {
        email: em,
        type: "mentorship",
        price: 60000,
      },
    })
      .then(function (res) {
        setIsLoading(false);
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setIsRender(!isRender);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const handleClose = () => {
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Subscribe to watch",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const handleSubmitRequest = (e) => {
    e.preventDefault();
    const fullName = e.target.elements.fName.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const title = e.target.elements.title.value;
    const question = e.target.elements.question.value;

    if (fullName === "") {
      notify("Full name required");
    } else if (email === "") {
      notify("Email required");
    } else if (phone === "") {
      notify("Phone Number required");
    } else if (title === "") {
      notify("Course Title required");
    } else if (question === "") {
      notify("Question required");
    } else {
      setIsLoading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/sendQuestion.php`,
        data: {
          fullName: fullName,
          email: email,
          phone: phone,
          title: title,
          question: question,
        },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          setIsLoading(false);
          if (data.error) {
            console.log(data.error);
          } else {
            setIsLoading(false);
            success(
              "Your Question have been sent to the Admin/Coach and you will get a response in your email on the Live Session Schedules"
            );
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleSubscription = () => {
    localStorage.setItem("isSub", true);
    window.location.href = "https://www.youtube.com/c/magnusmediatv";
  };

  return (
    <div className={mentorshipStyles.previewBox}>
      <Header />

      <div className={mentorshipStyles.previewContainer}>
        <div className={mentorshipStyles.imgBoxContain}>
          {!isPay ? (
            <img src={coachImg} alt="thumbnail" />
          ) : (
            <div className={mentorshipStyles.VideoYoutubeBox2}>
              <iframe
                className={mentorshipStyles.iframeStyle}
                title="Youtube"
                aria-hidden="true"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${link.liveLink}?autoplay=1>
                          <img src=https://img.youtube.com/vi/${link.liveLink}/hqdefault.jpg alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a></style>`}
              ></iframe>
            </div>
          )}
        </div>

        <div className={mentorshipStyles.containerText}>
          {!isPay ? (
            <h2>
              Enjoy Transformative Coaching/Mentorship & prepare yourself for
              Exclusive Camp Experience
            </h2>
          ) : null}

          {!isPay ? (
            <p className={mentorshipStyles.containerPtext}>
              This Coaching/Mentorship plan offers a 2 weeks personalized live
              stream discussion session with your facilitator according to
              course schedule, and exclusive access to industry insights. Master
              the art of film and media, and gear up for an unforgettable 2 to
              3-day camp tour for film production, where you'll delve into
              hands-on production and network with like-minded creatives.
            </p>
          ) : null}

          {!isPay ? (
            <div className={mentorshipStyles.learnBox}>
              <p className={mentorshipStyles.learnBoxText}>
                Benefits and Features
              </p>
              <div>
                <li>2 Weeks duration for Coaching /Mentorship</li>
                <li>
                  Drop Questions bothering you in your field to get answers .
                </li>
                <li>
                  2 to 3-Day camp for film project, networking and tourism (
                  Arrival Date will be committed to you)
                </li>
                <li>Accomodation Reserved for you.</li>
              </div>
            </div>
          ) : null}

          {!isPay ? (
            <div className={mentorshipStyles.priceBox}>
              <p>NGN 60,000</p>
            </div>
          ) : null}

          {isPay ? (
            <div className={mentorshipStyles.mentText}>
              <h2>Get to Us Directly</h2>
              <p>
                Drop your Questions and Worries for Admin, Courses and to your
                Facilitor. (Kindly note that this will be attended to on LIVE
                SESSION. Check your email)
              </p>
            </div>
          ) : null}

          {isPay ? (
            <div>
              <form onSubmit={handleSubmitRequest}>
                <TextInput
                  placeHolder="Full Name"
                  labelName="Full Name"
                  name="fName"
                />
                <InputFlex
                  labelName1="Your Email"
                  labelName2="Your Phone Number"
                  placeHolder1="Your Email"
                  placeHolder2="Your Phone Number"
                  name1="email"
                  name2="phone"
                />

                <div className={mentorshipStyles.selectInputBox}>
                  <select name="title">
                    <option value="">Course Title</option>
                    <option value="Acting">Acting</option>
                    <option value="Video Editing">Video Editing</option>
                    <option value="Screen Writing">Screen Writing</option>
                    <option value="Drone Piloting">Drone Piloting</option>
                    <option value="Cinematography">Cinematography</option>
                    <option value="Computer Appreciation">
                      Computer Appreciation
                    </option>
                    <option value="Film Making and Production Management">
                      Film Making and Production Management
                    </option>
                  </select>
                </div>
                <TextArea placeHolder="Ask Question" name="question" />

                <ButtonPrimary btnValue="Submit Question" />
              </form>
            </div>
          ) : null}

          {!isPay ? (
            <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => (
                <button
                  className={mentorshipStyles.button}
                  onClick={() => {
                    if (auth) {
                      initializePayment(handleSuccess, handleClose);
                    } else {
                      navigate("/signup");
                    }
                  }}
                >
                  Pay to Join Coaching/MentorShip
                </button>
              )}
            </PaystackConsumer>
          ) : null}
        </div>
      </div>

      <Footer />
      <ToastContainer theme="light" />
      {loading ? <Preloader /> : null}
      {sub ? (
        <div>
          <div
            className={mentorshipStyles.modal}
            onClick={() => setSub(false)}
          ></div>
          <div className={mentorshipStyles.modalContent}>
            <h2>Subscribe to our Youtube Channel</h2>
            <p>To get interesting Media,Tech and Lifestyle Contents</p>
            <div className={mentorshipStyles.imgBox}>
              <img src={youtubeImg} alt="Youtube Img" />
            </div>

            <div className={mentorshipStyles.btnBoxSub}>
              <button
                className={mentorshipStyles.btnBoxSub1}
                onClick={handleSubscription}
              >
                Subscribe Now
              </button>
              <button onClick={() => setSub(false)}>Subscribe Later</button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MentorShip;
