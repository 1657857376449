import React from "react";
import shimStyles from "./ShimmerSingle.module.css";

const ShimmerSingle = () => {
  return (
    <div className={shimStyles.container}>
      <article>
        <div className={shimStyles.line}></div>
        <div className={shimStyles.shimmer}></div>
      </article>
    </div>
  );
};

export default ShimmerSingle;
