import React, { useState, useEffect } from "react";
import navStyles from "./Nav.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoLockClosed } from "react-icons/io5";
import { FaUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { HiLocationMarker } from "react-icons/hi";
import { CgMenuOreos } from "react-icons/cg";
import AOS from "aos";
import "aos/dist/aos.css";

const Nav = (props) => {
  const auth = localStorage.getItem("auth");
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const handleGoToLogin = () => navigate("/signin");
  const handleLogout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("em");
    navigate("/signin");
  };
  useEffect(() => {
    AOS.init();
  }, []);

  const handleShowMenu = () => {
    setShowMenu(true);
  };

  const closeShowMenu = () => {
    setShowMenu(false);
  };
  return (
    <div>
      <nav>
        <ul className={navStyles.navbar}>
          <li>
            <Link to="/courses"> Courses</Link>
          </li>
          <li>
            <Link to="/coaching"> Mentorship/Coaching</Link>
          </li>

          <li>
            <Link to="/membership"> Membership </Link>
          </li>

          <li>
            <Link to="/movies">Watch Movies </Link>
          </li>

          <li>
            <Link to="/store">Store</Link>
          </li>

          {props.isUser ? (
            <div
              className={navStyles.boxName}
              onClick={() => setShowCard(true)}
            >
              <span>{props.data.email.split("")[0]}</span>
            </div>
          ) : null}

          <li className={navStyles.btnNavBox}>
            {auth ? (
              <button className={navStyles.btnSignOut} onClick={handleLogout}>
                <IoLockClosed className={navStyles.lockIcon} />
                Sign out
              </button>
            ) : (
              <button className={navStyles.btnSignin} onClick={handleGoToLogin}>
                Sign in
              </button>
            )}
          </li>

          <CgMenuOreos
            className={navStyles.menuIcon}
            onClick={handleShowMenu}
          />
        </ul>
      </nav>

      {showCard ? (
        <div
          className={navStyles.infoCardBox}
          onClick={() => setShowCard(false)}
          data-aos="fade-up"
        >
          <div className={navStyles.infoCardContainer}>
            <div className={navStyles.infoText}>
              <h2>Dashboard</h2>
            </div>

            <div className={navStyles.infoText2}>
              <FaUser className={navStyles.iconText} />
              <span className={navStyles.iconText1}>
                {props.isUser ? props.data.fullname : null}
              </span>
            </div>

            <div className={navStyles.infoText2}>
              <MdEmail className={navStyles.iconText} />
              <span className={navStyles.iconText1}>
                {props.isUser ? props.data.email : null}
              </span>
            </div>

            <div className={navStyles.infoText2}>
              <FaPhone className={navStyles.iconText} />
              <span className={navStyles.iconText1}>
                {props.isUser ? props.data.phoneNumber : null}
              </span>
            </div>

            <div className={navStyles.infoText2}>
              <HiLocationMarker className={navStyles.iconText} />
              <span className={navStyles.iconText1}>
                {props.isUser ? props.data.addres : null}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {showMenu ? (
        <>
          <div
            className={navStyles.menuContentBox}
            onClick={closeShowMenu}
          ></div>

          <div className={navStyles.menuContent} data-aos="fade-left">
            <div className={navStyles.menuTexth2}>
              <h2>Menu</h2>
            </div>

            <div className={navStyles.menuNav}>
              <div className={navStyles.menuNavItems}>
                <Link to="/">Home</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/courses">Courses</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/coaching">Mentorship/Coaching</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/membership">Membership </Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/movies">Watch Movies </Link>
              </div>

              <div className={navStyles.menuNavItems}>
                <Link to="/store">Store</Link>
              </div>

              <div className={navStyles.menuNavItems}>
                {auth ? (
                  <button
                    className={navStyles.btnSignOut}
                    onClick={handleLogout}
                  >
                    <IoLockClosed className={navStyles.lockIcon} />
                    Sign out
                  </button>
                ) : (
                  <button
                    className={navStyles.btnSignin2}
                    onClick={handleGoToLogin}
                  >
                    Sign in
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Nav;
