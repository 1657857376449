import React from "react";
import movieCardStyles from "./MovieCard.module.css";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";

const MovieCard = (props) => {
  return (
    <div className={movieCardStyles.storeCard}>
      <div className={movieCardStyles.storeImg}>
        <img src={props.img} alt="img" />
      </div>
      <div className={movieCardStyles.storeText}>
        <h2>Title: {props.movieTitle}</h2>
      </div>

      <div className={movieCardStyles.storeText}>
        <h2>Duration: {props.movieDuration}</h2>
      </div>

      <div className={movieCardStyles.storeDesc}>
        <p>{props.movieDesc}</p>
      </div>

      <ButtonPrimary btnValue="Preview" btnAction={props.showMovie} />
    </div>
  );
};

export default MovieCard;
