import React from "react";
import testimonialStyles from "./TestimonialCard.module.css";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";
import ratings from "../../assets/ratings.png";

const TestimonialCard = (props) => {
  return (
    <div className={testimonialStyles.testimonialCardBox}>
      <div className={testimonialStyles.testimonialCardImg}>
        <img src={props.pic} alt="pic" />
      </div>

      <div className={testimonialStyles.cardText}>
        <FaQuoteLeft className={testimonialStyles.icon} />
        <p>{props.testimonialText}</p>
        <FaQuoteRight className={testimonialStyles.icon} />
      </div>

      <div className={testimonialStyles.imgRatings}>
        <p>{props.ratingName}</p>
        <img src={ratings} alt="ratings" />
        <img src={ratings} alt="ratings" />
        <img src={ratings} alt="ratings" />
        <img src={ratings} alt="ratings" />
        <img src={ratings} alt="ratings" />
      </div>
    </div>
  );
};

export default TestimonialCard;
