import React from "react";
import Header from "../../components/Header/Header";
import Hero from "./Hero/Hero";
import coursesStyles from "./Courses.module.css";
import Preview from "./Preview/Preview";
import { titleUpdater } from "../../utilities/titleUpdater";

const Courses = () => {
  titleUpdater("Course Preview");
  return (
    <div className={coursesStyles.container}>
      <Header />
      <Hero />
      <Preview />
    </div>
  );
};

export default Courses;
