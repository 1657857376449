import React, { useEffect, useState } from "react";
import introStyles from "./Intro.module.css";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdOutlineCardMembership } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { baseUrl } from "../../utilities/apiUrl";

const Intro = () => {
  const [video, setVideo] = useState({});
  const [isloading, setIsLoading] = useState(true);
  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getHomeVideo.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setIsLoading(false);
          setVideo(data);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);
  const navigate = useNavigate();
  return (
    <div className={introStyles.introbox}>
      <div className={introStyles.introTextBox}>
        <h2>DESIGNED TO MAKE YOU A PROFESSIONAL ACTOR OR MEDIA PRACTITIONER</h2>

        <p className={introStyles.introText1}>
          This platform is your gateway to success in the entertainment and film
          industry. Inspired by our Founder with over 27 years of experience in
          Film & Media Business, created to nurture, connect you and stand by
          you as you complete the 3 stages below.
        </p>
        <div className={introStyles.introBoxIcon}>
          <HiOutlineAcademicCap className={introStyles.icon} />
          <p>Comprehensive Course</p>
        </div>
        <div className={introStyles.introBoxIcon}>
          <HiOutlineUsers className={introStyles.icon} />
          <p>Mentorship/Coaching</p>
        </div>
        <div className={introStyles.introBoxIcon}>
          <MdOutlineCardMembership className={introStyles.icon} />
          <p>Membership</p>
        </div>

        <p className={introStyles.introText1}>
          Kindly click JOIN NOW to get started.
        </p>

        <div className={introStyles.btnBox}>
          <button
            onClick={() => {
              navigate("/join_us");
              window.scrollTo(0, 0);
            }}
          >
            Join now
          </button>
        </div>
      </div>

      {!isloading ? (
        <div className={introStyles.videoBox}>
          <video autoPlay={true} controls muted loop preload="none">
            <source
              src={`${baseUrl}/api/uploads/${video.video_name}`}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>
      ) : null}
    </div>
  );
};
export default Intro;
