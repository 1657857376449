import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import loginStyles from "./LoginPage.module.css";
import TextInput from "../../components/TextInput/TextInput";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import ButtonTransparent from "../../components/ButtonTransparent/ButtonTransparent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../components/Preloader/Preloader";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const LoginPage = () => {
  titleUpdater("Sign in to your account");

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      navigate(`/join_us`);
    }
    // eslint-disable-next-line
  }, []);

  const customId = "id-static";
  const navigate = useNavigate();
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });
  const [loading, setLoading] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    if (event.target.email.value === "") {
      notify("Email Address Required");
    } else if (password === "") {
      notify("Password Required");
    } else {
      setLoading(true);

      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/signin.php`,
        data: { email: email, password: password },
      })
        .then(function (res) {
          setLoading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            localStorage.setItem("auth", true);
            localStorage.setItem("em", email);
            navigate(`/join_us`);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  return (
    <div className={loginStyles.formBox}>
      <form className={loginStyles.formContainer} onSubmit={handleLogin}>
        <h2>Welcome Back 👋🏿</h2>
        <p>Sign into your account to get started</p>
        <TextInput
          placeHolder="Email"
          type="text"
          labelName="Email Address"
          name="email"
        />
        <TextInput
          placeHolder="Password"
          type="password"
          labelName="Password"
          name="password"
        />
        <Link to="/forgotPassword">Forgot Password ?</Link>

        <ButtonPrimary btnValue="Sign in" />
        <ButtonTransparent
          linkName="Don't have an account? Sign up"
          linkPath="/signup"
        />
      </form>

      <ToastContainer theme="light" />
      {loading ? <Preloader /> : null}
    </div>
  );
};

export default LoginPage;
