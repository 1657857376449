import React, { useEffect, useState } from "react";
import courseSampleStyles from "../CourseSamples/CourseSamples.module.css";
import CourseSampleCard from "../CourseSampleCard/CourseSampleCard";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { baseUrl } from "../../utilities/apiUrl";
import ShimmerLoader from "../ShimmerLoader/ShimmerLoader";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

const CourseSamples = () => {
  const navigate = useNavigate();
  const [dataItems, setDataItems] = useState([]);
  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getCourses.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setDataItems(data.courses);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  }, []);

  return (
    <div className={courseSampleStyles.courseSampleBox}>
      <div className={courseSampleStyles.courseSampleHeadText}>
        <h2>Explore Premium Courses</h2>
        <p>
          Each course is designed with a specific goal that will help you along
          your Media Career journey as you enroll.
        </p>
      </div>

      {dataItems.length > 0 ? (
        <div className={courseSampleStyles.courseCardBox}>
          <CourseSampleCard
            courseImg={dataItems[3].thumbnail}
            courseName={dataItems[3].course_name}
            courseDescription={dataItems[3].course_desc1}
          />
          <CourseSampleCard
            courseImg={dataItems[4].thumbnail}
            courseName={dataItems[4].course_name}
            courseDescription={dataItems[4].course_desc1}
          />
          <CourseSampleCard
            courseImg={dataItems[1].thumbnail}
            courseName={dataItems[1].course_name}
            courseDescription={dataItems[1].course_desc1}
          />
          <CourseSampleCard
            courseImg={dataItems[6].thumbnail}
            courseName={dataItems[6].course_name}
            courseDescription={dataItems[6].course_desc1}
          />
          <CourseSampleCard
            courseImg={dataItems[5].thumbnail}
            courseName={dataItems[5].course_name}
            courseDescription={dataItems[5].course_desc1}
          />
        </div>
      ) : (
        <ShimmerLoader />
      )}

      <div className={courseSampleStyles.btnSamp}>
        <ButtonPrimary
          btnValue="Join now"
          btnAction={() => {
            navigate("/join_us");
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </div>
  );
};

export default CourseSamples;
