import React from "react";
import heroStyles from "./Hero.module.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className={heroStyles.hero}>
      <div className={heroStyles.overlay}></div>
      <div className={heroStyles.herotext}>
        <h2>Comprehensive Courses</h2>
        <button onClick={() => navigate("/join_us")}>Get Started</button>
      </div>
    </div>
  );
};
export default Hero;
