import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import LoginPage from "./pages/Login/LoginPage";
import SignupPage from "./pages/Signup/SignupPage";
import Courses from "./pages/Courses/Courses";
import CoursePreview from "./components/CoursePreview/CoursePreview";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import JoinUs from "./pages/Join_Us/JoinUs";
import MentorShip from "./components/Mentorship/MentorShip";
import Store from "./pages/Store/Store";
import StoreProductView from "./components/StoreProductView/StoreProductView";
import Membership from "./components/Membership/Membership";
import WatchMovies from "./pages/WatchMovies/WatchMovies";
import ViewMovie from "./components/ViewMovie/ViewMovie";
import Terms from "./pages/Terms/Terms";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import LoginAdmin from "./components/Admin/Login";
import Dashboard from "./components/Admin/Dashboard";
import CourseAdmin from "./components/Admin/Courses";
import Movies from "./components/Admin/Movies";
import StoreAdmin from "./components/Admin/Store";
import ArtistProfile from "./components/Admin/ArtisProfile";
import TestimonialAdmin from "./components/Admin/Testimonials";
import { Navigate } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/course" element={<CoursePreview />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/join_us" element={<JoinUs />} />
          <Route path="/coaching" element={<MentorShip />} />
          <Route path="/store" element={<Store />} />
          <Route path="/product" element={<StoreProductView />} />
          <Route path="/movies" element={<WatchMovies />} />
          <Route path="/movie" element={<ViewMovie />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin" element={<LoginAdmin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Course-list" element={<CourseAdmin />} />
          <Route path="/movie-list" element={<Movies />} />
          <Route path="/store-list" element={<StoreAdmin />} />
          <Route path="/artist-profiles" element={<ArtistProfile />} />
          <Route path="/testimonials" element={<TestimonialAdmin />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
