import React, { useEffect, useState } from "react";
import coursePreviewStyles from "./CoursePreview.module.css";
import Header from "../Header/Header";
import { MdOndemandVideo } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import Footer from "../Footer/Footer";
import { convertPrice } from "../../utilities/convertPrice";
import Preloader from "../../components/Preloader/Preloader";
import { PaystackConsumer } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utilities/apiUrl";
import { CiUnlock } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import youtubeImg from "../../assets/youtube.jpg";
import { titleUpdater } from "../../utilities/titleUpdater";
import $ from "jquery";

const CoursePreview = () => {
  titleUpdater(
    "Apply for a Comprehensive Media Course programe on Acting, Video Editing, Screen Writing, Drone Piloting, Cinematography, Skit Making/Content Creation, Film Making and Production Management."
  );
  const em = localStorage.getItem("em");
  const isSub = localStorage.getItem("isSub");
  const navigate = useNavigate();
  const auth = localStorage.getItem("auth");
  const [course, setCourse] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [learnItems, setLearnItems] = useState([]);
  const [sub, setSub] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [videos, setVideos] = useState([]);
  const id = window.location.href.split("?id=")[1];
  const apiKey = process.env.REACT_APP_API_KEY;
  const idMain = id.split("&")[0];
  const config = {
    reference: new Date().getTime().toString(),
    email: auth ? em : "",
    amount: show ? (Number(course.course_price) + 1000) * 100 : 0,
    publicKey: apiKey,
  };

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 30000,
    });
  };

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getCourse.php`,
      data: { id: idMain },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setCourse(data);
          setShow(true);
          setIsLoading(false);
          setTimeout(() => {
            if (isSub) {
              setSub(false);
            } else {
              setSub(true);
            }
          }, 1500);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getLearnText.php`,
      data: { id: idMain },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setLearnItems(data.items);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    if (em) {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/getPayment.php`,
        data: { email: em },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            if (data.error === "Payment expired") {
              notify(`${data.error} please renew your subscription`);
            }
          } else {
            const value = data.payments.filter(
              (item, index) =>
                item.payer_email === em && item.course_id === idMain
            );
            if (value.length > 0) {
              setIsPay(true);
              $.ajax({
                type: "POST",
                url: `${baseUrl}/api/getCourseVideos.php`,
                data: { id: idMain },
              })
                .then(function (res) {
                  let data = JSON.parse(res);
                  if (data.error) {
                    console.log(data.error);
                  } else {
                    setVideos(data.videos);
                  }
                })
                .fail(function (res) {
                  console.log(res);
                  setVideos([]);
                });
            } else {
              setIsPay(false);
            }
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
    // eslint-disable-next-line
  }, [isRender]);

  const handleSuccess = () => {
    setIsLoading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/updateUserPayment.php`,
      data: {
        email: em,
        courseId: course.id,
        courseName: course.course_name,
        coursePrice: course.course_price,
      },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setIsLoading(false);
          setIsRender(!isRender);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };
  const handleClose = () => {
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Subscribe to watch",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const handleSubscription = () => {
    localStorage.setItem("isSub", true);
    window.location.href = "https://www.youtube.com/c/magnusmediatv";
  };

  return (
    <div className={coursePreviewStyles.previewBox}>
      <Header />
      <div className={coursePreviewStyles.previewContainer}>
        <div className={coursePreviewStyles.imgBoxContain}>
          {isPay && videos.length > 0
            ? videos.map((video, index) => {
                if (video.type === "intro") {
                  return (
                    <div key={index}>
                      <div className={coursePreviewStyles.VideoYoutubeBox2}>
                        <iframe
                          className={coursePreviewStyles.iframeStyle}
                          title="Youtube"
                          aria-hidden="true"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${video.video_name}?autoplay=1>
                          <img src=${baseUrl}/api/uploads/${course.thumbnail} alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a>
                                </style>`}
                        ></iframe>
                      </div>
                    </div>
                  );
                } else {
                  return false;
                }
              })
            : null}

          {!isPay ? (
            <img
              src={`${baseUrl}/api/uploads/${course.thumbnail}`}
              alt="thumbnail"
            />
          ) : null}
        </div>

        <div className={coursePreviewStyles.containerText}>
          <h2>{show ? course.course_name : null}</h2>
          {!isPay ? (
            <p className={coursePreviewStyles.containerPtext}>
              {show ? course.course_description : null}
            </p>
          ) : null}

          {!isPay ? (
            <div className={coursePreviewStyles.learnBox}>
              <p className={coursePreviewStyles.learnBoxText}>
                In this Course You'll Learn
              </p>
              <div>
                {learnItems.length > 0
                  ? learnItems.map((item, index) => {
                      return <li key={item.id}>{item.item}</li>;
                    })
                  : null}
              </div>
            </div>
          ) : null}

          <div className={coursePreviewStyles.priceBox}>
            {!isPay ? (
              <p> {show ? convertPrice(course.course_price) : null}</p>
            ) : null}
          </div>

          {isPay && videos.length > 0 ? (
            <div className={coursePreviewStyles.unlockBox}>
              <CiUnlock className={coursePreviewStyles.icon} />
              <p>
                You have Paid for this Course, and all the course videos are now
                unlocked you can now watch and Learn.
              </p>
            </div>
          ) : null}

          {isPay && videos.length <= 0 ? (
            <div className={coursePreviewStyles.boxerr2}>
              <div className={coursePreviewStyles.iconRedBox}>
                <CiUnlock className={coursePreviewStyles.iconRed} />
              </div>
              <p>
                You have Paid for this Course, but there is currently No Course
                Video Yet for this course, Kindly hang on as a Course video will
                be uploaded soon
              </p>
            </div>
          ) : null}

          {!isPay ? (
            <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => (
                <button
                  className={coursePreviewStyles.button}
                  onClick={() => {
                    if (auth) {
                      initializePayment(handleSuccess, handleClose);
                    } else {
                      navigate("/signup");
                    }
                  }}
                >
                  Pay to Join the Class
                </button>
              )}
            </PaystackConsumer>
          ) : null}
        </div>
      </div>
      <div className={coursePreviewStyles.boxCourse1}>
        {isPay ? (
          <div className={coursePreviewStyles.boxIcons}>
            <MdOndemandVideo />
            <p>Videos({videos.length > 0 ? videos.length : 0})</p>
          </div>
        ) : null}

        <div
          className={
            isPay
              ? coursePreviewStyles.boxCourse21
              : coursePreviewStyles.boxCourse2
          }
        >
          {videos.length > 0 && !isPay
            ? videos.map((video, index) => {
                return (
                  <div className={coursePreviewStyles.courseImg} key={video.id}>
                    <img
                      src={`${baseUrl}/api/uploads/${course.thumbnail}`}
                      alt="courseimg"
                    />
                    <CiLock className={coursePreviewStyles.imgIcon} />
                  </div>
                );
              })
            : videos.map((video, index) => {
                return (
                  <div key={index}>
                    <div className={coursePreviewStyles.VideoYoutubeBox2}>
                      <iframe
                        className={coursePreviewStyles.iframeStyle}
                        title="Youtube"
                        aria-hidden="true"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${video.video_name}?autoplay=1>
                          <img src=${baseUrl}/api/uploads/${course.thumbnail} alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a></style>`}
                      ></iframe>
                    </div>

                    <h3 className={coursePreviewStyles.typeText}>
                      {video.type}
                    </h3>
                  </div>
                );
              })}
        </div>
      </div>
      <Footer />
      {loading ? <Preloader /> : null}
      {sub ? (
        <div>
          <div
            className={coursePreviewStyles.modal}
            onClick={() => setSub(false)}
          ></div>
          <div className={coursePreviewStyles.modalContent}>
            <h2>Subscribe to our Youtube Channel</h2>
            <p>To get interesting Media,Tech and Lifestyle Contents</p>
            <div
              className={coursePreviewStyles.imgBox}
              onClick={handleSubscription}
            >
              <img src={youtubeImg} alt="Youtube Img" />
            </div>

            <div className={coursePreviewStyles.btnBoxSub}>
              <button
                className={coursePreviewStyles.btnBoxSub1}
                onClick={handleSubscription}
              >
                Subscribe Now
              </button>
              <button onClick={() => setSub(false)}>Subscribe Later</button>
            </div>
          </div>
        </div>
      ) : null}

      <ToastContainer theme="light" />
    </div>
  );
};

export default CoursePreview;
