import React, { useState, useEffect, useRef } from "react";
import storeProdStyles from "./ViewMovie.module.css";
import Header from "../Header/Header";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import $ from "jquery";
import { baseUrl } from "../../utilities/apiUrl";
import Preloader from "../Preloader/Preloader";
import Footer from "../Footer/Footer";
import { convertPrice } from "../../utilities/convertPrice";
import { ToastContainer, toast } from "react-toastify";
import { PaystackConsumer } from "react-paystack";

const ViewMovie = () => {
  const dataInfo = JSON.parse(localStorage.getItem("info"));
  const apiKey = process.env.REACT_APP_API_KEY;
  const [loading, setloading] = useState(true);
  const [product, setProduct] = useState([]);
  const [billInfo, setbillInfo] = useState(false);
  const [billInfo2, setbillInfo2] = useState(false);
  const [price, setPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showSum, setShowSum] = useState(false);
  const [render, setIsrender] = useState(false);
  const [isUser, setIsUser] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const id = window.location.href.split("?id=")[1];
  const fName = useRef();
  const email = useRef();
  const phone = useRef();
  const prodId = id.split("&")[0];
  const uAuth = localStorage.getItem("uAuth");
  let info = {};

  const config = {
    reference: new Date().getTime().toString(),
    email: dataInfo ? dataInfo.email : "",
    amount: product ? (Number(price) + 100) * 100 : 0,
    publicKey: apiKey,
  };

  const customId = "id-static";
  const notify = (msg) =>
    toast.error(msg, {
      toastId: customId,
    });

  const success = (msg) =>
    toast.success(msg, {
      toastId: customId,
      position: "top-center",
      autoClose: 30000,
    });

  useEffect(() => {
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getMovie.php`,
      data: { id: prodId },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setProduct(data);
          setloading(false);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    if (uAuth) {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/getMovieAccount.php`,
        data: { email: uAuth, movieId: prodId },
      })
        .then(function (res) {
          setloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            if (data.error === "Payment expired") {
              notify("Payment Expired, Please check your mail");
            }
          } else {
            const value = data.payments.filter(
              (item, index) => item.email === uAuth && item.movie_id === prodId
            );
            if (value.length > 0) {
              setIsUser(data);
            } else {
              setIsUser("");
            }
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }

    // eslint-disable-next-line
  }, [render]);

  const handleBuyProduct = () => {
    setShowModal(true);
    const info = JSON.parse(localStorage.getItem("info"));

    if (info) {
      setbillInfo2(true);
      setbillInfo(false);
    } else {
      setbillInfo2(false);
      setbillInfo(true);
    }
  };

  const handleGoBack = () => {
    const data = JSON.parse(localStorage.getItem("info"));
    if (data) {
      setbillInfo(false);
      setShowSum(false);
      setbillInfo2(true);
    } else {
      setShowSum(false);
      setbillInfo2(false);
      setbillInfo(true);
    }
  };

  const handleSubmitInfo = (e) => {
    e.preventDefault();
    const fullname = e.target.elements.fullname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;

    if (fullname === "") {
      notify("Full name required");
    } else if (email === "") {
      notify("Email required");
    } else if (phone === "") {
      notify("Phone number required");
    } else {
      setloading(true);
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/createMovieUser.php`,
        data: {
          fullname: fullname,
          email: email,
          phone: phone,
        },
      })
        .then(function (res) {
          setloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            info = {
              fullname: fullname,
              email: email,
              phone: phone,
              isUser: true,
            };
            localStorage.setItem("info", JSON.stringify(info));
            setbillInfo(false);
            setShowSum(true);
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const handleClose = () => {
    console.log("closed");
  };
  const handleAccountSignin = () => {
    setbillInfo(false);
    setbillInfo2(true);
  };

  const handleAccountSignup = () => {
    setbillInfo(true);
    setbillInfo2(false);
  };

  const handleSuccess = () => {
    setloading(true);
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/movieAccount.php`,
      data: {
        fullname: dataInfo.fullname,
        email: dataInfo.email,
        phone: dataInfo.phone,
        price: price,
        referalCode: referalCode,
        id: prodId,
        movieTitle: product.movie_title,
      },
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setloading(false);
          let data = JSON.parse(res);
          if (data.error) {
            console.log(data.error);
          } else {
            setShowModal(false);
            setShowSum(false);
            setbillInfo(false);
            setbillInfo2(false);
            localStorage.setItem("uAuth", dataInfo.email);
            setIsrender(!render);
            success(
              "Enjoy the Movie, subscription Successful, Please check your mail"
            );
          }
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const handleSigninAuth = (e) => {
    setloading(true);
    e.preventDefault();
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/signinMovieAccount.php`,
      data: {
        email: email,
        phone: phone,
      },
    })
      .then(function (res) {
        setloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          notify(data.error);
        } else {
          localStorage.setItem("uAuth", email);
          $.ajax({
            type: "POST",
            url: `${baseUrl}/api/getMovieAccount.php`,
            data: { email: email, movieId: prodId },
          })
            .then(function (res) {
              setloading(false);
              let data2 = JSON.parse(res);
              if (data2.error) {
                setloading(true);
                $.ajax({
                  type: "POST",
                  url: `${baseUrl}/api/getUsermove2.php`,
                  data: { email: email },
                })
                  .then(function (res) {
                    setloading(false);
                    let data3 = JSON.parse(res);
                    if (data3.error) {
                      console.log(data.error);
                    } else {
                      setIsUser("");
                      setShowModal(true);
                      setShowSum(true);
                      setbillInfo2(false);
                      setbillInfo(false);
                      localStorage.setItem(
                        "info",
                        JSON.stringify({
                          fullname: data3.fullname,
                          email: email,
                          phone: phone,
                        })
                      );
                    }
                  })
                  .fail(function (res) {
                    console.log(res);
                  });
              } else {
                const value = data2.payments.filter(
                  (item, index) =>
                    item.email === email && item.movie_id === prodId
                );
                if (value.length > 0) {
                  setIsUser(data2);
                  setShowModal(false);
                  setShowSum(false);
                  setbillInfo(false);
                  setbillInfo2(false);
                  setIsrender(!render);
                  window.scrollTo(0, 0);
                } else {
                  setIsUser("");
                  setShowModal(true);
                  setShowSum(true);
                  setbillInfo2(false);
                  setbillInfo(false);
                  localStorage.setItem(
                    "info",
                    JSON.stringify({
                      fullname: data2.payments[0].fullname,
                      email: email,
                      phone: phone,
                    })
                  );
                }
              }
            })
            .fail(function (res) {
              console.log(res);
            });
        }
      })
      .fail(function (res) {
        console.log(res);
      });
  };

  const componentProps = {
    ...config,
    text: "Subscribe to watch",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div>
      <Header />
      <div className={storeProdStyles.prodContainer}>
        <div className={storeProdStyles.prodImg}>
          {isUser !== "" ? (
            <div>
              <div className={storeProdStyles.VideoYoutubeBox2}>
                <iframe
                  className={storeProdStyles.iframeStyle}
                  title="Youtube"
                  aria-hidden="true"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  srcDoc={`<style>
                                * {
                          padding: 0;
                          margin: 0;
                          overflow: hidden;
                          }
                          
                          body, html {
                            height: 100%;
                          }
                          
                          img, svg {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                          }
                          svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                            transition: all 250ms ease-in-out;
                          }
                          body:hover svg {
                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                            transform: scale(1.2);
                          }
                        </style>
                        <a href=https://www.youtube.com/embed/${product.movie_video}?autoplay=1>
                          <img src=${baseUrl}/api/uploads/${product.movie_thumbnail} alt='Img thumbnail'>
                          <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                        </a></style>`}
                ></iframe>
              </div>
            </div>
          ) : (
            <img
              src={`${baseUrl}/api/uploads/${product.movie_thumbnail}`}
              alt="Img"
            />
          )}
        </div>

        <div className={storeProdStyles.prodText}>
          <h2>Title: {product.movie_title}</h2>
          <p className={storeProdStyles.prodText1}>
            Duration: {product.movie_duration}
          </p>

          <div className={storeProdStyles.desc}>
            <p>{product.movie_desc}</p>
          </div>

          {isUser === "" ? (
            <ButtonPrimary
              btnValue="Show Love To Watch Movie"
              btnAction={handleBuyProduct}
            />
          ) : null}
        </div>
      </div>

      {loading ? <Preloader /> : null}
      {showModal ? (
        <>
          <div
            className={storeProdStyles.modal}
            onClick={() => {
              setShowModal(false);
              setShowSum(false);
            }}
          ></div>
          <div className={storeProdStyles.modalContent}>
            {billInfo ? (
              <div className={storeProdStyles.billInfo}>
                <div className={storeProdStyles.billHeader}>
                  <h2>Sign Up</h2>
                  <p>Please fill the form below to continue</p>
                </div>

                <form onSubmit={handleSubmitInfo}>
                  <div className={storeProdStyles.billForm}>
                    <label>Full Name</label>
                    <input
                      placeholder="Full Name"
                      name="fullname"
                      ref={fName}
                    />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Email Address</label>
                    <input
                      placeholder="Email Address"
                      name="email"
                      ref={email}
                    />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Phone Number</label>
                    <input
                      placeholder="Phone Number"
                      name="phone"
                      ref={phone}
                    />
                  </div>
                  <ButtonPrimary btnValue="Continue" />

                  <div
                    className={storeProdStyles.billFormLink}
                    onClick={handleAccountSignin}
                  >
                    <p>Already have an account? Sign in</p>
                  </div>
                </form>
              </div>
            ) : null}

            {billInfo2 ? (
              <div className={storeProdStyles.billInfo}>
                <div className={storeProdStyles.billHeader}>
                  <h2>Sign In</h2>
                  <p>Log into your account to continue</p>
                </div>

                <form onSubmit={handleSigninAuth}>
                  <div className={storeProdStyles.billForm}>
                    <label>Email Address</label>
                    <input placeholder="Email Address" name="email" />
                  </div>

                  <div className={storeProdStyles.billForm}>
                    <label>Phone Number</label>
                    <input placeholder="Phone Number" name="phone" />
                  </div>
                  <ButtonPrimary btnValue="Sign in" />

                  <div
                    className={storeProdStyles.billFormLink}
                    onClick={handleAccountSignup}
                  >
                    <p>Don't have an account? Sign Up</p>
                  </div>
                </form>
              </div>
            ) : null}

            {showSum ? (
              <div className={storeProdStyles.orderSumBox}>
                <p className={storeProdStyles.backBtn} onClick={handleGoBack}>
                  Back
                </p>
                <h2>Contribute to watch Movie</h2>
                <p className={storeProdStyles.textFormp}>
                  To support this movie project, please enter the your choice of
                  amount to show some love and watch the movie
                </p>

                <p className={storeProdStyles.textFormp2}>
                  You will be charged a Transaction Fee: {convertPrice("100")}
                </p>

                <div className={storeProdStyles.billForm}>
                  <div className={storeProdStyles.billAmtBox}>
                    <label>Amount</label>
                    <label>{convertPrice(price)}</label>
                  </div>
                  <input
                    placeholder="Amount"
                    name="Amount"
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                </div>
                <div className={storeProdStyles.billForm}>
                  <div className={storeProdStyles.billAmtBox}>
                    <label>Referal Code (optional) </label>
                  </div>
                  <input
                    placeholder="Referal Code (Optional)"
                    name="referal"
                    onChange={(e) => setReferalCode(e.target.value)}
                  />
                </div>

                <PaystackConsumer {...componentProps}>
                  {({ initializePayment }) => (
                    <ButtonPrimary
                      btnValue="Pay Now"
                      btnAction={() => {
                        if (price < 500) {
                          notify("Minimum amount is NGN 500");
                        } else {
                          initializePayment(handleSuccess, handleClose);
                        }
                      }}
                    />
                  )}
                </PaystackConsumer>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <Footer />
      <ToastContainer theme="light" />
    </div>
  );
};

export default ViewMovie;
