import React from "react";
import homeStyles from "./Home.module.css";
import Header from "../../components/Header/Header";
import Intro from "../../components/Intro/Intro";
import Problems from "../../components/Problems/Problems";
import VideoPlayback from "../../components/VideoPlayback/VideoPlayback";
import CourseSamples from "../../components/CourseSamples/CourseSamples";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";
import Solutions from "../../components/Solutions/Solutions";
import liveImg from "../../assets/live.png";
import HomeMovies from "../HomeMovies/HomeMovies";
import { titleUpdater } from "../../utilities/titleUpdater";

const Home = () => {
  titleUpdater(
    "Your gateway to success in the entertainment and film industry. Inspired by our Founder with over 27 years of experience in Film & Media Business, created to nurture and connect you through your Media Journey."
  );

  return (
    <div className={homeStyles.homeContainer}>
      <Header />
      <Intro />
      <VideoPlayback />
      <Problems />
      <Solutions />
      <CourseSamples />
      <HomeMovies />
      <Testimonials />
      <Footer />

      <div className={homeStyles.whatsappLive}>
        <a href="https://api.whatsapp.com/send/?phone=+2348032684423">
          <div className={homeStyles.whatsappBox}>
            <div>
              <span>Live chat</span>
            </div>
            <div className={homeStyles.whatsappBoxImg}>
              <img src={liveImg} alt="liveImg" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Home;
