import React from "react";
import preloaderStyles from "./Preloader.module.css";

function Preloader() {
  return (
    <div className={preloaderStyles.loaderContainer}>
      <div className={preloaderStyles.loaderBox}>
        <div className={preloaderStyles.loader}>Loading...</div>
        <span>Wait a moment...</span>
      </div>
    </div>
  );
}
export default Preloader;
