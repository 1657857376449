import React, { useEffect } from "react";
import termsStyles from "./Terms.module.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { titleUpdater } from "../../utilities/titleUpdater";

const Terms = () => {
  titleUpdater("Terms of service Agreement");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={termsStyles.container}>
      <Header />

      <div className={termsStyles.termsContainer}>
        <div className={termsStyles.termsBox}>
          <div className={termsStyles.termsBox1}>
            <h2>Terms of Service Agreement</h2>
            <p>
              This Media Academy Terms of Service Agreement ("Agreement") is
              entered into by and between Magnus Media Academy, hereinafter
              referred to as the "Academy," and the individual or entity
              agreeing to these terms, hereinafter referred to as the "User" or
              "Customer."
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>1. Acceptance of Terms</h2>
            <p>
              By accessing, using, or enrolling in any courses, programs, or
              services offered by the Academy, the User agrees to be bound by
              the terms and conditions outlined in this Agreement.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>2. Enrollment and Payment</h2>
            <p>
              2.1 <b>Enrollment</b>: The User agrees to provide accurate,
              current, and complete information during the enrollment process.
              The Academy reserves the right to suspend or terminate access to
              its services if the provided information is found to be false or
              misleading.
            </p>
            <p>
              2.2 <b>Payment</b>: The User agrees to pay all fees associated
              with the selected courses or programs as outlined in the Academy's
              payment policies. Failure to make timely payments may result in
              the suspension or termination of access to the Academy's services.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>3. Intellectual Property</h2>
            <p>
              All content, materials, and intellectual property provided by the
              Academy, including but not limited to courses, videos, and written
              materials, are the exclusive property of the Academy. The User
              agrees not to reproduce, distribute, or modify any content without
              the express written consent of the Academy.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>4. Code of Conduct</h2>
            <p>
              The User agrees to adhere to the Academy's code of conduct, which
              includes but is not limited to:
            </p>
            <p>
              4.1 <b>Respect</b>: Treating instructors, staff, and fellow
              students with respect and professionalism.
            </p>
            <p>
              4.2 <b>Compliance</b>: Following all applicable laws and
              regulations during the use of Academy services.
            </p>
            <p>
              4.3 <b>Ethical Use</b>: Using Academy services for educational
              purposes only and refraining from any unethical or illegal
              activities.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>5. Termination</h2>
            <p>
              The Academy reserves the right to terminate or suspend access to
              its services at its discretion, without prior notice, for any
              violation of these terms, misconduct, or any other reason deemed
              necessary by the Academy.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>6. Disclaimer of Warranty</h2>
            <p>
              The Academy provides its services "as is" and makes no warranties,
              expressed or implied, regarding the accuracy, completeness, or
              suitability of the content provided.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>7. Limitation of Liability</h2>
            <p>
              The Academy shall not be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of the
              use or inability to use its services.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>8. Governing Law</h2>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of Nigeria, without regard to its conflict of law
              principles.
            </p>
          </div>

          <div className={termsStyles.termBox2}>
            <h2>9. Changes to Terms</h2>
            <p>
              The Academy reserves the right to update or modify these terms at
              any time. Users will be notified of any changes, and continued use
              of the Academy's services after such notification constitutes
              acceptance of the revised terms.
            </p>
            <p>
              By enrolling in the Academy's courses or using its services, the
              User acknowledges having read, understood, and agreed to the terms
              and conditions outlined in this Agreement.
            </p>
            <p>
              <b>Date:</b> 29th february 2024
            </p>
            <p>
              <b>Address:</b> No. 26, 1st Avenue off Damijah Road, Trans-Ekulu,
              Enugu State, Nigeria, West Africa
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
