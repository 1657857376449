import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import Preloader from "../../components/Preloader/Preloader";
import $ from "jquery";

const ArtistProfile = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getArtistProfile.php`,
    })
      .then(function (res) {
        setisloading(false);
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          console.log(data);
          setCourses(data.artists);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh3}>
            <h2>Artist Profiles</h2>
          </div>

          {courses.length > 0
            ? courses.map((course, index) => {
                return (
                  <div className={adminStyles.mainBox4} key={index}>
                    <div className={adminStyles.imgBox4}>
                      <img
                        src={`${baseUrl}/api/uploads/${course.img}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Full Name</p>
                      <span>{course.fullname}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Email Address</p>
                      <span>{course.email}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Phone Number</p>
                      <span>{course.phone}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Address</p>
                      <span>{course.addres}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Gender</p>
                      <span>{course.gender}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Biography</p>
                      <span>{course.bio}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Bank Name</p>
                      <span>{course.bankName}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Account Name</p>
                      <span>{course.acctName}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Account Number</p>
                      <span>{course.acctNum}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Facebook Url</p>
                      <span>{course.facebook}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Instagram Url</p>
                      <span>{course.instagram}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Twitter Url</p>
                      <span>{course.twitter}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>TikTok Url</p>
                      <span>{course.tiktok}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Specialization or Skill</p>
                      <span>{course.skill}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Availability Status</p>
                      <span>{course.available}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Proficient Languages</p>
                      <span>{course.languag}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>Physical Ability</p>
                      <span>{course.physicalActivity}</span>
                    </div>

                    <div className={adminStyles.boxFData4}>
                      <p>iDoff Url</p>
                      <span>{course.idoffUrl}</span>
                    </div>
                    <div className={adminStyles.boxFData4}>
                      <p>Profile ID</p>
                      <span>{course.profileId}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <Footer />

      {isloading ? <Preloader /> : null}
    </div>
  );
};

export default ArtistProfile;
