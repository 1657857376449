import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import adminStyles from "./Adminstyles.module.css";
import { baseUrl } from "../../utilities/apiUrl";
import { convertPrice } from "../../utilities/convertPrice";
import { IoVideocamOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import $ from "jquery";

const Dashboard = () => {
  const auth = localStorage.getItem("isAdmin");
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [mentees, setMentees] = useState([]);
  const [members, setMembers] = useState([]);
  const [probs, setProbs] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [showModal3, setshowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploadWidth, setUploadWidth] = useState(0);
  const [showupload, setShowUpload] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [id1, setId1] = useState("");
  const fileRef = useRef();
  const probRef = useRef();

  const notify = (msg) => {
    toast.error(msg, {
      autoClose: 30000,
      toastId: "73883",
    });
  };

  const success = (msg) => {
    toast.success(msg, {
      autoClose: 30000,
      toastId: "73883",
    });
  };

  useEffect(() => {
    if (!auth) {
      navigate("/admin");
    }
    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getAllUsers.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setUsers(data.users);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getAllMentorsPayment.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setMentees(data.mentees);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getAllMembersPayment.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setMembers(data.mentees);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getProbs.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setProbs(data.probs);
        }
      })
      .fail(function (res) {
        console.log(res);
      });

    $.ajax({
      type: "POST",
      url: `${baseUrl}/api/getSolutions.php`,
    })
      .then(function (res) {
        let data = JSON.parse(res);
        if (data.error) {
          console.log(data.error);
        } else {
          setSolutions(data.solutions);
        }
      })
      .fail(function (res) {
        console.log(res);
      });
    // eslint-disable-next-line
  }, [isRender]);

  // click file input
  const handleSelectFile = () => {
    fileRef.current.click();
  };

  // choose file from file input
  const handleFileSelected = () => {
    if (fileRef.current.files.length > 0) {
      setFileName(fileRef.current.files[0].name);
    }
  };

  //handle Upload of file
  const handleUpload = () => {
    const file = fileRef.current.files[0];

    if (fileRef.current.files.length === 0) {
      notify("Select an intro video");
    } else {
      let formData = new FormData();
      formData.append("file", file);
      setShowUpload(true);
      let http = new XMLHttpRequest();
      http.upload.addEventListener("progress", (event) => {
        let percent = (event.loaded / event.total) * 100;
        let status = `${Math.round(percent)}`;
        setUploadWidth(status);
      });

      http.onreadystatechange = function () {
        if (http.status === 200) {
          if (http.responseText) {
            const data = JSON.parse(http.responseText);
            if (data.error === "Sorry, only mp4 is allowed") {
              notify(data.error);
              setShowUpload(false);
            } else {
              setShowUpload(false);
              success("Intro Video uploaded");
              setFileName("");
              fileRef.current.value = "";
            }
          }
        }
      };

      http.open("post", `${baseUrl}/api/uploadIntroVideo.php`, true);
      http.send(formData);
    }
  };
  const showProbModal = (id, value) => {
    setshowModal3(true);
    setId1(id);
    setTimeout(() => {
      probRef.current.value = value;
    }, 300);
  };
  const addHandleText = (e) => {
    e.preventDefault();
    const item = e.target.item.value;

    if (item === "") {
      notify("Text required");
    } else {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateProbs.php`,
        data: { id: id1, item: item },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setshowModal3(false);
            success("Updated the text");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const showSolModal = (id, value) => {
    setShowModal4(true);
    setId1(id);
    setTimeout(() => {
      probRef.current.value = value;
    }, 300);
  };

  const addHandleText2 = (e) => {
    e.preventDefault();
    const item = e.target.item2.value;
    if (item === "") {
      notify("Text required");
    } else {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateSolutions.php`,
        data: { id: id1, item: item },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            setIsRender(!isRender);
            setShowModal4(false);
            success("Updated the text");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };

  const updateLiveurl = (e) => {
    e.preventDefault();
    const youId = e.target.youId.value;
    if (youId === "") {
      notify("Youtube ID required");
    } else {
      $.ajax({
        type: "POST",
        url: `${baseUrl}/api/updateYoutubeID.php`,
        data: { id: youId },
      })
        .then(function (res) {
          let data = JSON.parse(res);
          if (data.error) {
            notify(data.error);
          } else {
            e.target.youId.value = "";
            setIsRender(!isRender);
            success("Updated the Link");
          }
        })
        .fail(function (res) {
          console.log(res);
        });
    }
  };
  return (
    <div>
      <Header />
      <div className={adminStyles.containerBox}>
        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh1}>
            <h2>Home Settings</h2>

            <div className={adminStyles.containId}>
              <form onSubmit={updateLiveurl}>
                <label>Add Going Live Youtube ID</label>
                <input placeholder="Add Youtube ID" name="youId" />
                <button>Add Link</button>
              </form>
            </div>
          </div>

          <div className={adminStyles.mainBoxNn}>
            <h4>Upload Intro Video</h4>

            <div className={adminStyles.uploadVidBox}>
              <div className={adminStyles.uploadVid} onClick={handleSelectFile}>
                <IoVideocamOutline className={adminStyles.uploadVidC} />
                {fileName === "" ? <p>Choose Intro Video</p> : null}

                <input
                  hidden
                  type="file"
                  name="file"
                  ref={fileRef}
                  onChange={handleFileSelected}
                />

                {fileName !== "" ? <p>{fileName}</p> : null}
              </div>

              <div>
                <button onClick={handleUpload}>Upload</button>
              </div>
            </div>
          </div>
        </div>

        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh2}>
            <h2>Accounts Created ({users.length})</h2>
          </div>

          {users.length > 0
            ? users.map((user, index) => {
                return (
                  <div className={adminStyles.mainBox1} key={user.id}>
                    <div className={adminStyles.imgBox}>
                      <img
                        src={`${baseUrl}/api/uploads/${user.profilepicture}`}
                        alt="img"
                      />
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Full Name</p>
                      <span>{user.fullname}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Email</p>
                      <span>{user.email}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Date Of Birth</p>
                      <span>{user.dob}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Address</p>
                      <span>{user.addres}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Phone Number</p>
                      <span>{user.phoneNumber}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Parent Number</p>
                      <span>{user.parentNumber}</span>
                    </div>

                    <div className={adminStyles.boxFData1}>
                      <p>Gender</p>
                      <span>{user.gender}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh2}>
            <h2>Paid Mentees</h2>
          </div>

          {mentees.length > 0
            ? mentees.map((user, index) => {
                return (
                  <div className={adminStyles.mainBox} key={index}>
                    <div className={adminStyles.boxFData}>
                      <p>Email</p>
                      <span>{user.email}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Amount Paid</p>
                      <span>{convertPrice(user.price)}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Payment Date</p>
                      <span>{`20${user.yea}/${user.mont}/${user.dai}`}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Programe Stage</p>
                      <span>{user.pay_type}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.containerBox1}>
          <div className={adminStyles.dashh2}>
            <h2>Paid Members</h2>
          </div>

          {members.length > 0
            ? members.map((user, index) => {
                return (
                  <div className={adminStyles.mainBox} key={index}>
                    <div className={adminStyles.boxFData}>
                      <p>Email</p>
                      <span>{user.email}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Amount Paid</p>
                      <span>{convertPrice(user.price)}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Payment Date</p>
                      <span>{`20${user.yea}/${user.mont}/${user.dai}`}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Programe Stage</p>
                      <span>{user.pay_type}</span>
                    </div>

                    <div className={adminStyles.boxFData}>
                      <p>Profile ID</p>
                      <span>{user.profileId}</span>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.boxActP}>
          <h3>Problems Facing Actors/Media Practioners</h3>
          {probs.length > 0
            ? probs.map((item, index) => {
                return (
                  <div key={index}>
                    <p>
                      {item.id}. {item.item}
                    </p>
                    <button onClick={() => showProbModal(item.id, item.item)}>
                      Edit
                    </button>
                  </div>
                );
              })
            : null}
        </div>

        <div className={adminStyles.boxActP}>
          <h3>Solutions For Actors/Media Practioners</h3>
          {solutions.length > 0
            ? solutions.map((item, index) => {
                return (
                  <div key={index}>
                    <p>
                      {item.id}. {item.item}
                    </p>
                    <button onClick={() => showSolModal(item.id, item.item)}>
                      Edit
                    </button>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <Footer />

      {showupload ? (
        <div className={adminStyles.modalBox}>
          <div className={adminStyles.modalContent}>
            <p>Uploading Video</p>

            <span>{uploadWidth}%</span>
            <div className={adminStyles.uploadBox24}>
              <div
                style={{
                  width: `${uploadWidth}%`,
                  backgroundColor: "#f10707",
                  padding: 15,
                  borderRadius: 5,
                }}
              ></div>
            </div>
          </div>
        </div>
      ) : null}

      {showModal3 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setshowModal3(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Problem</p>
            <form onSubmit={addHandleText}>
              <div className={adminStyles.formMainInput}>
                <label>Edit Problem</label>
                <textarea
                  placeholder="Edit Problem"
                  name="item"
                  ref={probRef}
                />
              </div>

              <ButtonPrimary btnValue="Add" />
            </form>
          </div>
        </div>
      ) : null}

      {showModal4 ? (
        <div>
          <div
            className={adminStyles.modalBox}
            onClick={() => setShowModal4(false)}
          ></div>

          <div className={adminStyles.modalContent}>
            <p>Edit Solution</p>
            <form onSubmit={addHandleText2}>
              <div className={adminStyles.formMainInput}>
                <label>Edit Solution</label>
                <textarea
                  placeholder="Edit Solution"
                  name="item2"
                  ref={probRef}
                />
              </div>
              <ButtonPrimary btnValue="Add" />
            </form>
          </div>
        </div>
      ) : null}

      <ToastContainer theme="light" />
    </div>
  );
};

export default Dashboard;
